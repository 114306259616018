import React, { useMemo, useState } from 'react';
import '../styles/AddNewField.scss'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';


export default function AddNewFolder({ isAddNewFolder, setIsAddNewFolder, onConfirm, isSubField, scrollTargetRef }) {

    const { t } = useTranslation(['editFieldMenu', 'translation']);
    const [newFolderName, setNewFolderName] = useState('');
    const translatedFieldTypes = useMemo(() => [t('single', { ns: 'editFieldMenu' }), t('multiple', { ns: 'editFieldMenu' })], [t]);
    const [newFolderType, setNewFolderType] = useState(false);


    const folderOptions = [
        { value: false, label: translatedFieldTypes[0] },
        { value: true, label: translatedFieldTypes[1] }
    ]


    const folderNameDescription = t('folderNameDesc', { ns: 'editFieldMenu' });
    const folderTypeDescription = t('folderTypeDesc', { ns: 'editFieldMenu' });


    const addFolderSteps = [
        {
            step: 1,
            title: t('fieldName', { ns: 'editFieldMenu' }),
            description: folderNameDescription,
            input: <input
                type="text"
                placeholder={t('typeAnswer', { ns: 'editFieldMenu' })} className="add-folder-input"
                onChange={(e) => setNewFolderName(e.target.value)}
                required={true}
            />
        },
        {
            step: 2,
            title: t('fieldType', { ns: 'editFieldMenu' }),
            description: folderTypeDescription,
            input:
                <>
                    <div className="toggle">
                        <input type="radio" name="folderType" value={folderOptions[0].value} id="single" checked={newFolderType === false} onChange={() => setNewFolderType(folderOptions[0].value)} />
                        <label htmlFor="single">{folderOptions[0].label}</label>

                        <input type="radio" name="folderType" value={folderOptions[1].value} id="multiple" onChange={() => setNewFolderType(folderOptions[1].value)} checked={newFolderType === true} />
                        <label htmlFor="multiple">{folderOptions[1].label}</label>
                    </div>
                    <p className='folderType-description'>{newFolderType ? t('multipleDescription', { ns: 'editFieldMenu' }) : t('singleDescription', { ns: 'editFieldMenu' })}</p>
                </>
        }
    ]

    const handleNext = () => {
        if (!newFolderName) {
            toast.error(t('fillAllFields', { ns: 'errors' }));
            return;
        }
        const newField = {
            name: newFolderName,
            id: uuidv4(),
            type: "record",
            multiple: newFolderType,
            fields: []
        }
        onConfirm(newField)
        setNewFolderName("");
    }

    return (
        <>
            {isAddNewFolder &&
                <div ref={scrollTargetRef} className="add-folder-card" style={{ width: !isSubField ? '90%' : 'auto', marginBottom: !isSubField ? '20px' : '0' }}>
                    <div className='add-folder-wrapper'>
                        {addFolderSteps.map((step, idx) => {
                            return (
                                <div className='add-folder-input-container' key={idx}>
                                    <p className="add-folder-desc">{step.description}</p>
                                    {step.input}
                                </div>
                            )
                        })}
                    </div>
                    <div className="buttons-container">
                        <button className='cancel-button' onClick={() => setIsAddNewFolder(false)}>{t('cancel', { ns: 'translation' })}</button>
                        <button className='confirm-btn btn' onClick={handleNext} disabled={!newFolderName}>{t('next', { ns: 'translation' })}</button>
                    </div>
                </div>
            }
        </>
    )
}
