
import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
    name: 'modals',
    initialState: {
        isInboxDeleteModalOpen: false,
        selectedInbox: null
    },
    reducers: {
        showInboxDeleteModal: (state) => {
            state.isInboxDeleteModalOpen = true;
        },
        hideInboxDeleteModal: (state) => {
            state.isInboxDeleteModalOpen = false;
        },
        setSelectedInbox: (state, action) => {
            state.selectedInbox = action.payload;
        }
    }
});

export const { showInboxDeleteModal, hideInboxDeleteModal, setSelectedInbox } = modalSlice.actions;

export default modalSlice.reducer;
