import React from 'react'
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import '../../styles/BackButton.scss';
export default function BackButton() {
    const navigate = useNavigate();
    const { inboxId } = useParams();
    const { t } = useTranslation('translation');

    const handleBack = (inboxId) => {
        inboxId ? navigate(`/inboxes/${inboxId}`) : navigate('/')
    }
    return (
        <div className="back-button-container" onClick={() => handleBack(inboxId)}>
            <IoArrowBackOutline className="back-button-icon" />
            <div className='back-button-text'>{t('back')}</div>
        </div>
    )
}
