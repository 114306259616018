import '../../styles/Modals.scss';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { ModalContext } from '../../contexts/ModalContext';

export default function InboxNameModal({ setShowModal, setInboxName, inboxName, handleSubmit }) {

    const { t } = useTranslation();
    const { setModalOn } = useContext(ModalContext);

    const handleClick = () => {
        setModalOn(false)
        setShowModal(false)
        setInboxName('');
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit();
        } else if (e.key === 'Escape') {
            handleClick();
        }
    }

    return (
        <div className="modal-container modal-container__inbox">
            <h3 className="inbox-title">{t('inboxSettings')}</h3>
            <div className="inbox-name-container">
                <label htmlFor="inbox-name" className="inbox-name-label">{t('inboxName')}</label>
                <input type="text" placeholder={t('inboxNamePlaceholder')} className="inbox-name-input" value={inboxName} onChange={(e) => setInboxName(e.target.value)} onKeyDown={handleKeyDown} />
            </div>
            <div className="data-privacy-info">
                <p className="data-privacy-info-text">{t('dataPrivacyNote')}</p>
            </div>
            <div className='modal-buttons-container'>
                <button className="cancel-btn btn cancel-inbox" onClick={handleClick}>{t('cancel')}</button>
                <button onClick={handleSubmit} className="create-btn btn">{t('Create')}</button>
            </div>
        </div>
    )
}
