import { useTranslation } from 'react-i18next';
import '../../styles/Button.scss';

export default function UploadButton({ setShowUploadModal }) {
    const { t } = useTranslation();

    return (
        <button className="close-btn btn" onClick={() => setShowUploadModal(false)}>{t('close')}</button>
    )
}
