import { Link } from 'react-router-dom'
import '../styles/Sidebar.scss';
import logo from '../assets/logo.png';
import DocTypeMenu from './DocTypeMenu';
import { shallowEqual, useSelector } from 'react-redux';
import { MdAdd } from "react-icons/md";
import { useTranslation } from 'react-i18next';


export default function Sidebar() {
    const { inbox } = useSelector(state => ({
        inbox: state.inbox,
    }), shallowEqual)
    const { inboxData, isSuccess } = inbox;
    const { t } = useTranslation();

    return (<>
        <div className='content-container'>
            <div className='sidenav'>
                <Link to='/'><img src={logo} alt="logo" className='logo' /></Link>
                <Link to='/add-inbox' className='add-inbox-container'>
                    <MdAdd className='add-inbox-icon' />
                    <h4 className='add-inbox'>{t('newInbox')}</h4>
                </Link>
                {inboxData.length > 0 && isSuccess && (<>
                    <h3 className='document-types'>{t('MyInboxes')}</h3>
                    <DocTypeMenu />
                </>)}
            </div>
        </div>
    </>
    )
}
