import React from 'react'
import { useSelector } from 'react-redux';
import { LuClipboardCopy } from "react-icons/lu";
import { useTranslation } from 'react-i18next';


export default function EmailAddressInSettings({ copyToClipboard }) {
    const { emailAddress } = useSelector(state => state.inbox);
    const { t } = useTranslation('generalSettings')

    return (
        <>
            {emailAddress &&
                <div className='email-address-container inbox-item-container'>
                    <p className='inbox-item-label'>{t('emailAddress', { ns: 'generalSettings' })} </p>
                    <div className='inbox-settings-data'>
                        <p className='inbox-settings-data__text'>{emailAddress}</p>
                        <div className='settings-copy-paste'>
                            <LuClipboardCopy className='settings-copy-paste-icon' onClick={() => copyToClipboard(emailAddress)}
                                title='Copy to clipboard' />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}
