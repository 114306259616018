import { useState } from 'react';
import Hero from '../components/Hero';
import '../styles/ForgotPassword.scss';
import forgotPswLogo from '../assets/illustrations/forgotPsw.svg';
import { useTranslation, Trans } from 'react-i18next';
import SendLinkButton from '../components/buttons/SendLinkButton';
import OrElement from '../components/OrElement';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { forgotPassword, reset } from '../features/auth/authSlice';


export default function ForgotPassword() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();
    const { isError } = useSelector((state) => state.auth)
    const dispatch = useDispatch();

    const onChange = (e) => {
        setEmail(e.target.value);
        localStorage.setItem('email', e.target.value);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setShowLoader(true);
        dispatch(forgotPassword({ email }));
        setShowLoader(false);
        navigate('/email-sent');
        if (isError) {
            setShowLoader(false);
            toast.error(t('generalError', { ns: 'errors' }));
        }
        dispatch(reset())
    }

    return (
        <main className='main-container main-reset-psw-container'>
            <Hero />
            {showLoader ? <Loader /> : (
                <div className='reset-psw-container'>
                    <img src={forgotPswLogo} alt="password illustration" className='forgot-psw-illustration' />
                    <div className='reset-psw-text-container'>
                        <h3 className='reset-psw-title'>{t('cantLogin', { ns: 'userManagement' })}</h3>
                        <p className='reset-psw-text'>{t('cantLoginText', { ns: 'userManagement' })}</p>
                    </div>
                    <form className='reset-psw-form' onSubmit={onSubmit}>
                        <div className='reset-psw-inputs'>
                            <label className='reset-psw-label'>{t('email', { ns: 'userManagement' })}</label>
                            <input className='reset-psw-input' type='email' placeholder='john.doe@example.com' id='email' name='email' value={email} onChange={onChange} />
                        </div>
                        <SendLinkButton />
                    </form>
                    <OrElement />
                    <Trans i18nKey={'userManagement:createAccount'}><Link to='/signup' className='signup-link'>Create a new account</Link></Trans>
                </div>
            )}
        </main>
    )
}
