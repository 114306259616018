import React, { useEffect, useState } from 'react';
// import '../styles/DataValidation.scss'
import RightSideMenu from '../components/RightSideMenu.jsx';
import SVGContainer from '../components/SVGContainer';
import { svgWidthSize, svgHeightSize } from '../../config.js';
import { Resizable } from "re-resizable";
import { Page } from '../../pageUtils.js';
import { useLocation, useParams } from 'react-router-dom';
import ThumbnailsPreview from '../components/ThumbnailsPreview';
import { demoData } from '../demoData.js'
import { useDispatch } from 'react-redux';
import { cancelAndResetFiles, resetDocumentsData } from '../../features/files/fileSlice.js';
import { resetInbox } from '../../features/inboxes/inboxSlice.js';
import { logoutUser } from '../../features/auth/authSlice.js';



export default function DataValidationDemo() {
    const [isHovered, setIsHovered] = useState(false);
    const [svgWidth, setSvgWidth] = useState(svgWidthSize);
    const [svgHeight, setSvgHeight] = useState(svgHeightSize);
    const [borderHighlightStyles, setBorderHighlightStyles] = useState([]);

    const updateBorderStyles = (highlightStyles) => {
        setBorderHighlightStyles(highlightStyles);
    }

    const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);
    const { demoDocType } = useParams()
    const [page, setPage] = useState(null);
    const [validatedData, setValidatedData] = useState([]);
    const [dataExtraction, setDataExtraction] = useState('');
    const [documentOCR, setDocumentOCR] = useState('');
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (location.pathname.includes('demo')) {
            dispatch(cancelAndResetFiles());
            dispatch(resetInbox());
            dispatch(resetDocumentsData());
            dispatch(logoutUser());
        }
    }, [location.pathname, dispatch]);

    useEffect(() => {
        if (demoDocType) {
            setDataExtraction(demoData[demoDocType].dataExtraction)
            setDocumentOCR(demoData[demoDocType].documentOCR)
        }
    }, [demoDocType])


    useEffect(() => {
        if (dataExtraction) {
            setValidatedData(dataExtraction)
        }
    }, [dataExtraction])


    useEffect(() => {
        if (documentOCR && documentOCR.words) {
            setPage(new Page(documentOCR.words, documentOCR.boxes, documentOCR.width, documentOCR.height));
        }
    }, [documentOCR]);


    return (
        <div className='data-validation-main-container'>
            <div className="data-validation-container">
                <ThumbnailsPreview />
                <SVGContainer
                    borderHighlightStyles={borderHighlightStyles}
                    isHovered={isHovered}
                    svgHeight={svgHeight}
                    svgWidth={svgWidth}
                    setSvgHeight={setSvgHeight}
                    setSvgWidth={setSvgWidth}
                    page={page}
                    validatedData={validatedData}
                />
                <Resizable
                    defaultSize={{ width: 550 }}
                    handleClasses={{
                        top: "pointer-events-none",
                        bottom: "pointer-events-none",
                        right: "pointer-events-none",
                        topRight: "pointer-events-none",
                        bottomRight: "pointer-events-none",
                        bottomLeft: "pointer-events-none",
                        topLeft: "pointer-events-none",
                    }}
                    minWidth={300}
                    maxWidth={900}
                >
                    <RightSideMenu
                        updateBorderStyles={updateBorderStyles}
                        setBorderHighlightStyles={setBorderHighlightStyles}
                        svgWidth={svgWidth}
                        svgHeight={svgHeight}
                        setIsHovered={setIsHovered}
                        validatedData={validatedData}
                        setValidatedData={setValidatedData}
                        page={page}
                        documentOCR={documentOCR}
                        isEditMenuOpen={isEditMenuOpen}
                        setIsEditMenuOpen={setIsEditMenuOpen}
                    />
                </Resizable>
            </div>
        </div >
    )
}
