import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from 'i18next-http-backend';
import Cookies from 'js-cookie';
const previouslySelectedLanguage = Cookies.get('language');


i18n
    // load translation using http -> see /public/locales
    .use(Backend)
    // detect user language
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next

    .init({
        lng: previouslySelectedLanguage || 'en',
        debug: false,
        fallbackLng: 'en',
        ns: ['translation', 'userManagement', 'footer', 'errors', 'cookiePolicy', 'editFieldMenu', 'generalSettings'],
        defaultNS: 'translation',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });

export default i18n;
