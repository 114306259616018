
import { useTranslation } from 'react-i18next';
import '../../styles/Button.scss';

export default function SignUpButton() {
    const { t } = useTranslation(['translation', 'userManagement']);
    return (
        <button type='submit' className='signup-btn btn'>{t('signUp', { ns: 'userManagement' })}</button>
    )
}
