import axios from "axios";
import Cookies from 'js-cookie';
import { baseApi } from "../../config";



const login = async (userData) => {
    const user = {
        username: userData.email,
        password: userData.password
    }
    const formData = new URLSearchParams(user);

    const response = await axios.post(`${baseApi}/auth/cookie/login`, formData, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        withCredentials: true
    });

    if (response.status === 204 || response.status === 200) {
        Cookies.set('userInfo', user.username, { sameSite: 'none', secure: true });
        return user.username
    }

    return response.data;
}

const logout = async () => {
    Cookies.remove('userInfo');
    const response = await axios.post(`${baseApi}/auth/cookie/logout`, {})
    return response.data
}



const register = async (userData) => {
    const response = await axios.post(`${baseApi}/auth/register`, userData, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    if (response.status === 201) {

        // Send email confirmation
        await axios.post(`${baseApi}/auth/request-verify-token`, { email: response.data.email });
        // Login user
        await login(userData);
    }
    return response.data;
}

const forgotPassword = async (userEmail) => {
    const response = await axios.post(`${baseApi}/auth/forgot-password`, userEmail, {
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return response
}

const requestVerificationEmail = async (email) => {
    const response = await axios.post(`${baseApi}/auth/request-verify-token`, { email });
    return response
}

const verifyEmail = async (token) => {
    const response = await axios.post(`${baseApi}/auth/verify`, { token });
    return response
}


const authService = {
    register,
    login,
    logout,
    forgotPassword,
    requestVerificationEmail,
    verifyEmail

}

export default authService;
