import React from 'react'
import { RiDeleteBin6Line } from "react-icons/ri";
import '../../styles/AddDeleteFieldButton.scss';
import { useTranslation } from 'react-i18next';


export default function DeleteField({ handleDeleteField }) {
    const { t } = useTranslation();
    return (
        <>
            <div className='delete-field-button-container' onClick={handleDeleteField}>
                <RiDeleteBin6Line className='delete-field-button-icon' />
                <p className='delete-field-button-text'>{t('deleteFieldGroup', { ns: 'editFieldMenu' })}</p>
            </div>
        </>

    )
}
