import { removeDocument } from '../../features/files/fileSlice';
import { useDispatch } from 'react-redux';
import { RiDeleteBin6Line } from "react-icons/ri";
import '../../styles/Icons.scss';
import { useTranslation } from 'react-i18next';
export default function DeleteDocumentButton({ selectedFiles, setSelectedFiles, disabledButton }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const handleRemoveFile = () => {
        let docIds = [];
        for (const fileId in selectedFiles) {
            if (selectedFiles[fileId]) {
                docIds.push(fileId);
            }
        }
        docIds.forEach(docId => {
            dispatch(removeDocument(docId))
        })
        setSelectedFiles([]);
    }

    return (

        <div className={disabledButton ? "disabled-icon" : "delete-icon icon"}>
            <div className='icon-container'>
                <RiDeleteBin6Line className={disabledButton ? "disabled-icon" : "delete-icon icon"} onClick={handleRemoveFile} title={t('deleteDocument')} />
            </div>
        </div>
    )
}
