import success from '../../assets/successIcon.svg';
import { useTranslation } from 'react-i18next';
import '../../styles/Modals.scss';

export default function EmailVerifiedModal() {
    const { t } = useTranslation('userManagement');
    return (
        <div className="modal-container ">
            <img src={success} alt="success icon" className='success-icon' />
            <h3>{t('thankYou')}</h3>
            <p className='success email-verified'>{t('emailVerified')}</p>
        </div>
    )
}
