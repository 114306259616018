import React from 'react';
import { useTranslation } from 'react-i18next';
import { LuClipboardCopy } from "react-icons/lu";
import { useSelector } from 'react-redux';


export default function PipelineIdInSettings({ copyToClipboard }) {
    const { inboxDetails } = useSelector(state => state.inbox);
    const { t } = useTranslation('generalSettings')
    return (
        <div className='pipeline-id-container inbox-item-container'>
            <p className='pipeline-id-label inbox-item-label'>{t('pipelineID')}</p>
            <div className='inbox-settings-data'>
                <p className='inbox-settings-data__text'>{inboxDetails?.pipeline_id}</p>
                <div className='settings-copy-paste'>
                    <LuClipboardCopy className='settings-copy-paste-icon' onClick={() => copyToClipboard(inboxDetails?.pipeline_id)}
                        title='Copy to clipboard' />
                </div>
            </div>
        </div>
    )
}
