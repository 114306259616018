import { useTranslation } from 'react-i18next'
import '../styles/Hero.scss';
import { motion } from "framer-motion"


export default function Hero() {
    const { t } = useTranslation()

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: .8, delay: .1 }}
            className='hero-container'>
            <h1 className='hero'>{t('hero')} </h1>
        </motion.div>
    )
}
