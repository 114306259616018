import React from 'react';
import { HiDotsVertical } from "react-icons/hi";
import "../../styles/DotsOptionsRXMenu.scss";


export default function DotsOptionsRXMenu() {
    return (
        <div className='icon-container '>
            <HiDotsVertical className='tab-icon' />
        </div>
    )
}
