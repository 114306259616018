import error from '../../assets/errorIcon.svg';
import { useTranslation } from 'react-i18next';

import '../../styles/Modals.scss';
import SendNewLinkButton from '../buttons/SendNewLinkButton';

export default function ExpiredTokenErrorModal() {
    const { t } = useTranslation('errors');
    return (
        <div className="modal-container modal-container__expired">
            <img src={error} alt="error icon" className='error-icon' />
            <h3>{t('ops')}</h3>
            <h4>{t('wrong')}</h4>
            <p className='error '>{t('expiredLink')}</p>
            <SendNewLinkButton />
        </div>
    )
}
