import React from 'react'
import { useTranslation } from 'react-i18next';
import { LuClipboardCopy } from "react-icons/lu";
import { useSelector } from 'react-redux';


export default function InboxIdInSettings({ copyToClipboard }) {
    const { inboxDetails } = useSelector(state => state.inbox);
    const { t } = useTranslation('generalSettings')

    return (
        <div className='inbox-id-container inbox-item-container'>
            <p className='inbox-item-label'>{t('inboxID')} </p>
            <div className='inbox-settings-data'>
                <p className='inbox-settings-data__text'>{inboxDetails?.id}</p>

                <div className='settings-copy-paste'>
                    <LuClipboardCopy className='settings-copy-paste-icon' onClick={() => copyToClipboard(inboxDetails?.id)}
                        title='Copy to clipboard' />
                </div>
            </div>
        </div>
    )
}
