import React, { useEffect, useRef } from 'react'
import '../styles/DropdownMenu.scss'
import { useParams } from 'react-router-dom';
import { baseApi } from '../config';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { downloadCsv } from '../downloadCSV';
import { getDocumentExtraction } from '../features/files/fileSlice';

export default function MoreOptionsRXMenu({ setShowMoreOptions, showMoreOptions, iconRef }) {
    const { documentId } = useParams();
    const { documentsData } = useSelector(state => state.documents);
    const menuRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (menuRef.current && !menuRef.current.contains(e.target) && iconRef.current && !iconRef.current.contains(e.target)) {
                setShowMoreOptions(false);
            }
        }
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        }
    }, [menuRef, iconRef, showMoreOptions, setShowMoreOptions]);


    const handleDownloadCsv = async () => {
        await downloadCsv({
            baseApi,
            documentId,
            documentsData,
            setShowMenu: setShowMoreOptions,
            t
        });
    };

    const handleRecomputeExtraction = () => {
        dispatch(getDocumentExtraction({ docId: documentId, recompute: true }));
        setShowMoreOptions(false);
    }

    return (
        <div className='dropdown-menu' ref={menuRef}>
            <div className='dropdown-menu-btn' onClick={handleDownloadCsv}> {t('downloadCsv')} </div>
            <div className='dropdown-menu-btn' onClick={handleRecomputeExtraction}> {t('recomputeExtraction')} </div>
        </div>
    )
}
