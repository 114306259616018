import success from '../../assets/successIcon.svg';
import { useTranslation } from 'react-i18next';
import '../../styles/Modals.scss';

export default function RegisterModal() {
    const { t } = useTranslation('userManagement');
    return (
        <div className="modal-container modal-container__register">
            <img src={success} alt="success icon" className='success-icon' />
            <h3>{t('thankYou')}</h3>
            <p className='success'>{t('registrationSuccess')}</p>
            <p className='verify'>{t('verifyAccount')}</p>
        </div>
    )
}
