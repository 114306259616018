import React from 'react'
import { HiPlus } from "react-icons/hi";
import { useTranslation } from 'react-i18next';
import '../../styles/AddDeleteFieldButton.scss';

export default function AddNewFieldButton({ isSubField, handleAddNewField, ...props }) {

    const { t } = useTranslation(['editFieldMenu', 'translation']);
    const subFieldText = t('addNewField', { ns: 'editFieldMenu' });
    const fieldText = t('newFieldGroup', { ns: 'editFieldMenu' });

    return (
        <>
            <div className='add-field-button-container' onClick={() => handleAddNewField(props)} {...props} style={!isSubField ? { width: '90%', marginBottom: '20px' } : { padding: '10px', fontSize: '9px' }}>
                <HiPlus className='add-field-button-icon' />
                <p className='add-field-button-text' style={!isSubField ? {} : { fontSize: '14px' }}>{isSubField ? subFieldText : fieldText}</p>
            </div >
        </>
    )
}
