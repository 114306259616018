import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidDownArrow } from "react-icons/bi";
import '../styles/LanguageSelector.scss';
import { ModalContext } from '../contexts/ModalContext';
import Cookies from 'js-cookie';
import { useParams } from 'react-router-dom';


export default function LanguageSelector() {

    const { i18n } = useTranslation();
    const [showLanguages, setShowLanguages] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.resolvedLanguage);
    const { modalOn } = useContext(ModalContext);
    const { demoDocType } = useParams()




    useEffect(() => {
        if (demoDocType === 'bestellung' || demoDocType === 'energieausweis') {
            i18n.changeLanguage("de")
        } else if (demoDocType === 'purchaseOrder') {
            i18n.changeLanguage("en")
        } else {
            i18n.changeLanguage(selectedLanguage);
            setSelectedLanguage(selectedLanguage);
            Cookies.set('language', selectedLanguage, { expires: 365 });
        }
    }, [demoDocType, i18n]); // eslint-disable-line react-hooks/exhaustive-deps


    const locales = {
        en: { title: 'EN' },
        de: { title: 'DE' },
    };

    const toggleLanguages = () => {
        setShowLanguages(!showLanguages);
    };

    const changeLanguage = (locale) => {
        setSelectedLanguage(locale);
        i18n.changeLanguage(locale);
        Cookies.set('language', locale, { expires: 365 });
        setShowLanguages(false);
    };

    return (
        <div className={"language-selector"}>
            <div
                className={modalOn ? "selected-language selected-language__modal-active" : "selected-language"}
                onClick={toggleLanguages}
            >
                {locales[selectedLanguage].title}
                <BiSolidDownArrow className='down-arrow' />
            </div>
            {showLanguages && (
                <div className="language-dropdown">
                    {Object.keys(locales).map((locale) => (
                        locale !== selectedLanguage && (
                            <div key={locale}>
                                <button
                                    onClick={() => changeLanguage(locale)}
                                    className={selectedLanguage === locale ? 'active' : ''}
                                >
                                    {locales[locale].title}
                                </button>
                            </div>
                        )
                    ))}
                </div>
            )}
        </div>
    )
}
