import { useState } from "react";
import Hero from "../components/Hero";
import resetPswLogo from "../assets/illustrations/resetPsw.svg";
import { useTranslation } from 'react-i18next';
import SetPasswordButton from "../components/buttons/SetPasswordButton";
import { toast } from 'react-toastify';
import '../styles/ForgotPassword.scss';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseApi } from "../config";

export default function ResetPassword() {
    const { t } = useTranslation();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();
    // const [token, setToken] = useState('');
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');


    const onSubmit = async (e) => {
        e.preventDefault()
        if (password !== confirmPassword) {
            toast.error(t('passwordsDontMatch', { ns: 'errors' }));
        } else {
            try {
                await axios.post(`${baseApi}/auth/reset-password`, {
                    token,
                    password,
                },
                    {
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }
                )
                toast.success(t('passwordChanged', { ns: 'userManagement' }));
                navigate('/');
            } catch (error) {
                if (error.response.data.detail === 'RESET_PASSWORD_BAD_TOKEN') {
                    toast.error(t('expiredLink', { ns: 'errors' }));
                    navigate('/');
                } else if (error.response.data.detail.code === 'RESET_PASSWORD_INVALID_PASSWORD') {
                    toast.error(t('invalidPassword', { ns: 'errors' }));
                } else {
                    toast.error(t('generalError', { ns: 'errors' }));
                    navigate('/');
                }
            }
        }
    }

    if (!token) {
        navigate('/');
    }

    return (
        <main className='main-container main-new-password-container'>
            <Hero />
            <div className="new-password-container">
                <img src={resetPswLogo} alt="reset password illustration" className="reset-password-illustration" />
                <form className="new-password-form" onSubmit={onSubmit}>
                    <div className='new-password-inputs'>
                        <label className="new-password-label">New Password</label>
                        <input type="password" placeholder="New Password" className="new-password-input" onChange={(e) => setPassword(e.target.value)} autoComplete="new-password" />
                    </div>
                    <div className='new-password-inputs'>
                        <label className="new-password-label">Confirm Password</label>
                        <input type="password" placeholder="Confirm Password" className="new-password-input" onChange={(e) => setConfirmPassword(e.target.value)} />
                    </div>
                    <SetPasswordButton />
                </form>
            </div>
        </main>
    )
}
