import { api } from "../../api"

export const fetchAllTemplates = async (lang) => {
    const response = await api.get(`/templates?lang=${lang}`);
    return response.data;
}


export const postNewPipeline = async (newPipelineName, templateId, ocrId, chatId, lang) => {
    const response = await api.post('/pipelines', {
        name: newPipelineName,
        template: `${templateId}.${lang}`,
        ocr_id: ocrId || undefined,
        chat_id: chatId || undefined,
    })
    return response.data;
}
