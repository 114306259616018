import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import '../../styles/Button.scss';


export default function DemoSignUpButton({ setIsDemoPage }) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/signup')
        setIsDemoPage(false)
    }
    return (
        <button className='pink-signup-btn' onClick={() => handleClick()}>{t('freeSignUp', { ns: 'translation' })}</button>
    )
}
