import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { documentLimit } from '../config'
import { getAllDocuments, handleNext, handlePrevious } from '../features/files/fileSlice';
import { useParams } from 'react-router-dom';
import PreviousButton from './buttons/PreviousButton';
import NextButton from './buttons/NextButton';
import '../styles/DocumentsPagination.scss'

export default function DocumentsPagination() {
    const { documentsCount, offset } = useSelector(state => state.documents)
    const dispatch = useDispatch();
    const { inboxId } = useParams();
    const [fetchMoreDocuments, setFetchMoreDocuments] = useState(false)


    const handleShowLess = () => {
        dispatch(handlePrevious())
        setFetchMoreDocuments(true)
    }

    const handleShowNext = () => {
        dispatch(handleNext())
        setFetchMoreDocuments(true)
    }

    useEffect(() => {
        if (fetchMoreDocuments) {
            dispatch(getAllDocuments({ inboxID: inboxId, limit: documentLimit, offset: offset }));
            setFetchMoreDocuments(false)
        }
    }, [fetchMoreDocuments]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {documentsCount > documentLimit &&
                <div className='pagination-container' style={{ display: 'flex', justifyContent: 'center', width: '100%', gap: '1em', marginTop: 'auto' }}>
                    <PreviousButton handleShowLess={handleShowLess} />
                    <NextButton handleShowNext={handleShowNext} />
                </div>
            }
        </>
    )
}
