import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import '../styles/CreateAPIKey.scss'
import { useDispatch } from 'react-redux';
import { addNewAPIKey } from '../features/user/userSlice';
import { toast } from 'react-toastify';
import { defaultAPIKeyValidity } from '../config';

export default function CreateAPIKey({ setNewAPIKey }) {
    const [newKeyName, setNewKeyName] = useState('');
    const [newKeyExpiry, setNewKeyExpiry] = useState("");
    const [unlimitedExpiry, setUnlimitedExpiry] = useState(false);
    const { t } = useTranslation("userManagement");
    const dispatch = useDispatch();

    useEffect(() => {
        const now = new Date();
        const expiryDate = new Date(now.getTime() + defaultAPIKeyValidity);
        setNewKeyExpiry(expiryDate.toISOString().split('T')[0]);
    }, [])

    const handleCreate = () => {
        const expirationDate = new Date(newKeyExpiry).toISOString()
        if (expirationDate < new Date().toISOString()) {
            toast.error(t('invalidDate'));
            return
        }
        const newKey = {
            name: newKeyName || t("defaultAPIKeyName"),
            expire_at: unlimitedExpiry ? null : expirationDate
        }
        const promise = dispatch(addNewAPIKey(newKey));
        setNewKeyName('');
        setNewKeyExpiry(null);
        setNewAPIKey(promise);
    }

    const handleCancel = () => {
        setNewKeyName('');
        setNewKeyExpiry(null);
        setNewAPIKey(false);
    }

    const noExpiry = () => {
        setUnlimitedExpiry(!unlimitedExpiry);
    }


    return (
        <div className='create-api-key-container'>
            <div className='create-api-key-input-container'>
                <p className='create-api-key-input-title'>{t('APIKeyName')}</p>
                <input type="text" className='create-api-key-input' value={newKeyName} onChange={(e) => setNewKeyName(e.target.value)} required={true} />
            </div>
            <div className='create-api-key-input-container'>
                <p className='create-api-key-input-title'>{t('keyExpiry')}</p>
                <div className='input-container__date'>
                    <input
                        type="date"
                        className='create-api-key-input create-api-key-input__date'
                        value={unlimitedExpiry ? '' : newKeyExpiry}
                        style={{ color: unlimitedExpiry ? 'gray' : 'black' }}
                        onChange={(e) => setNewKeyExpiry(e.target.value)}
                        placeholder={unlimitedExpiry ? 'xx-xx-xxxx' : ''}
                        onClick={() => setUnlimitedExpiry(false)}

                    />
                    <div className='no-expiry-container'>
                        <input
                            type="checkbox"
                            id="no-expiry-checkbox"
                            checked={unlimitedExpiry}
                            onChange={() => noExpiry()}
                        />
                        <label htmlFor="no-expiry-checkbox" className='no-expiry'>
                            {t('noExpiry')}
                        </label>
                    </div>
                </div>
            </div>
            <div className='create-api-key-btn-container'>
                <button
                    onClick={() => handleCancel()}
                    className='btn cancel-btn cancel-btn__api-key create-api-key-btn'>
                    {t('cancel', {ns: "translation"})}
                </button>
                <button
                    onClick={() => handleCreate()}
                    className='btn create-btn create-api-key-btn'>
                    {t('createAPIKey')}
                </button>
            </div>
        </div>
    )
}
