import React from 'react';
import '../styles/NewInbox.scss';
import NewInboxPipeline from './NewInboxPipeline';
import { useTranslation } from 'react-i18next';
import BackButton from './buttons/BackButton';

export default function NewInbox() {
    const { t } = useTranslation();
    return (<>
        <div className='back-button-container'>
            <BackButton />
        </div>
        <div className='new-inbox-container'>
            <h1 className='new-inbox-title'>{t('createInbox')}</h1>
            <p className='new-inbox-text'>
                {t('createInboxText')}
            </p>
            <h2 className='new-inbox-subtitle'>{t('chooseModel')}</h2>
            <NewInboxPipeline />
        </div>
    </>

    )
}
