import { api } from "../../api"

export const fetchPipelineInfo = async (pipelineId) => {
    const response = await api.get(`/pipelines/${pipelineId}`);
    return response.data;
}

export const patchPipelineTemplate = async (pipelineId, newPipelineTemplate) => {
    const response = await api.patch(`/pipelines/${pipelineId}`, newPipelineTemplate);
    return response.data;
}
