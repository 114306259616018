import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import '../styles/DocTypeMenu.scss'
import { getAllDocuments, resetDocumentsData } from '../features/files/fileSlice';
import { useTranslation, Trans } from 'react-i18next';
import { useEffect, useState } from 'react';
import { getUserCredits, reset } from '../features/user/userSlice';
import { PiCoinsThin } from "react-icons/pi";
import { TiWarningOutline } from "react-icons/ti";
import { MdKeyboardArrowDown } from "react-icons/md";
import { getInboxInfo, getInboxesCount, resetEmails } from '../features/inboxes/inboxSlice';
import { inboxLimit } from '../config';
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { toast } from 'react-toastify';
import { setSelectedInbox, showInboxDeleteModal } from '../features/modals/modalsSlice';



export default function DocTypeMenu() {
    const { inboxId } = useParams();
    const { inboxData, inboxesCount, isInboxDeleted } = useSelector(state => state.inbox);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { fullDocumentExtraction } = useSelector(state => state.documents)
    const { userCredits } = useSelector(state => state.user)
    const [lowCredits, setLowCredits] = useState(false)
    const [newLimit, setNewLimit] = useState(inboxLimit)
    const [newLimitChanged, setNewLimitChanged] = useState(false)
    const [hoveredInboxId, setHoveredInboxId] = useState(null)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const navigate = useNavigate();

    const handleShowMore = () => {
        setNewLimit(oldLimit => oldLimit + inboxLimit)
        setNewLimitChanged(true)
    }

    useEffect(() => {
        const index = inboxData.findIndex(inbox => inbox.id === inboxId);
        if (index === -1) {
            dispatch(getInboxInfo({ limit: 1000 }));
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (newLimitChanged) {
            dispatch(getInboxInfo({ limit: newLimit }));
            setNewLimitChanged(false)
        }
    }, [newLimit, newLimitChanged]); // eslint-disable-line react-hooks/exhaustive-deps


    const handleClick = (inbox) => {
        dispatch(resetEmails())
        dispatch(resetDocumentsData())
        dispatch(getAllDocuments({ inboxID: inbox, offset: 0 }));
    }

    useEffect(() => {
        dispatch(getUserCredits())
        dispatch(reset())
    }, [dispatch, fullDocumentExtraction]);


    useEffect(() => {
        if (userCredits && userCredits < 5 || userCredits === 0) {
            setLowCredits(true)
        }
    }, [userCredits, lowCredits])

    const toggleDropdown = (e) => {
        e.stopPropagation();
        e.preventDefault()
        setIsDropdownOpen(prevState => !prevState);
    };

    const handleOnHover = (inboxId) => {
        setHoveredInboxId(inboxId)
        setIsDropdownOpen(false)
    }

    const handleShowInboxDeleteModal = (inboxId) => {
        dispatch(setSelectedInbox(inboxId));
        dispatch(showInboxDeleteModal());
    }

    useEffect(() => {
        if (isInboxDeleted) {
            toast.success(t('inboxDeleted'));
            dispatch(getInboxInfo({ limit: 1000 })).then(() => {
                navigate('/');
            });
            dispatch(getInboxesCount());
        }
    }, [isInboxDeleted]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {inboxData?.map((inbox, index) => {
                return (
                    <Link
                        to={`/inboxes/${inbox.id}`}
                        key={index}
                        className={inboxId === inbox.id ? 'document-type-wrapper active' : 'document-type-wrapper'}
                        onClick={() => handleClick(inbox.id)}
                        onMouseEnter={() => handleOnHover(inbox.id)}
                        onMouseLeave={() => setHoveredInboxId(null)}>
                        <p className='document-type-name'>{inbox.name}</p>
                        {hoveredInboxId === inbox.id &&
                            <div className='hover-overlay' onClick={toggleDropdown}>
                                <PiDotsThreeOutlineVerticalFill className="document-type-icon" />
                                {isDropdownOpen && <div className='inbox-dropdown'>
                                    <ul>
                                        <li
                                            onClick={() => handleShowInboxDeleteModal(hoveredInboxId)}
                                            className='inbox-dropdown-item'>{t('deleteInbox')}</li>
                                    </ul>
                                </div>}
                            </div>
                        }
                        {hoveredInboxId !== inbox.id && <p className='document-type-count'><span>{inbox.document_count}</span></p>}
                    </Link>
                )
            })}
            {inboxData?.length < inboxesCount &&
                <div className='show-more-container' onClick={handleShowMore}>
                    <MdKeyboardArrowDown className='show-more-icon' />
                    <p className='show-more-text' >More</p>
                </div>

            }
            {/* <div className='bottom-settings'>
                <Link className='bottom-settings-link' href="#">Account Settings</Link>
                <Link className='bottom-settings-link' href="#">Documentation</Link>
                <Link className='bottom-settings-link' href="#">API Key</Link>
            </div> */}
            {userCredits !== null &&
                <div className='bottom-settings'>
                    <div className='credit-left-container'>
                        {lowCredits ? <TiWarningOutline className="bottom-settings-icon bottom-settings-icon__warning" /> : <PiCoinsThin className="bottom-settings-icon bottom-settings-icon__coins" />}
                        <p className='bottom-settings-text credits'><span className={lowCredits ? 'credit-count-low' : 'credit-count'}>{userCredits}</span> {t('creditsLeft')}</p>
                    </div>
                    {lowCredits && <div className='credit-error-msg'>
                        <Trans t={t} i18nKey='lowCredit' ns='translation'> <a href={`mailto:${t('emailAddress')}`}>Contact us </a> to top up your account.</Trans>
                    </div>}
                </div>
            }
        </>
    )
}
