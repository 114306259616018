import { useEffect, useMemo, useState } from 'react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
    Navigate
} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import RootLayout from './Layout/RootLayout';
import Home from './pages/Home';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import EmailSent from './pages/EmailSent';
import NotFound from './pages/NotFound';
import Loader from './components/Loader';
import AccountVerified from './pages/AccountVerified';
import ResetPassword from './pages/ResetPassword';
import CookiePolicy from './pages/CookiePolicy';
import FileUpload from './pages/FileUpload';
import DataValidation from './pages/DataValidation';
import { useTranslation } from 'react-i18next';
import { logoutUser } from './features/auth/authSlice';
import { cancelAndResetFiles, resetDocumentsData } from './features/files/fileSlice';
import { getInboxInfo, getInboxesCount, resetInbox } from './features/inboxes/inboxSlice';
import { ModalContext } from './contexts/ModalContext';
import NewInbox from './components/NewInbox';
import InboxSettings from './components/InboxSettings';
import DataValidationDemo from './demo/pages/DataValidationDemo';
import APIKeys from './pages/APIKeys';


function App() {

    const { user, isLoggedIn } = useSelector(state => state.auth);
    const { isError, message } = useSelector(state => state.documents);
    const { t } = useTranslation(['translation', 'userManagement', 'errors']);
    const [sessionExpired, setSessionExpired] = useState(false);
    const { isLoading, isErrorInbox, messageInbox } = useSelector(state => state.inbox);
    const dispatch = useDispatch();
    const [modalOn, setModalOn] = useState(false);
    const { inboxData } = useSelector(state => state.inbox);

    useEffect(() => {
        if (isLoggedIn && user) {
            dispatch(getInboxInfo());
            dispatch(getInboxesCount())
        }
    }, [dispatch, isLoggedIn, user]);


    const shouldLogout = useMemo(() => {
        return (isError && message === 'Unauthorized' && !sessionExpired) || ((isLoggedIn && user) && isErrorInbox && messageInbox === 'Unauthorized' && !sessionExpired);
    }, [isError, message, sessionExpired, isLoggedIn, user, isErrorInbox, messageInbox]);

    useEffect(() => {
        if (shouldLogout) {
            toast.error(t('sessionExpiredMessage', { ns: 'errors' }), { toastId: 'sessionExpired1' });
            dispatch(cancelAndResetFiles());
            dispatch(resetInbox());
            dispatch(resetDocumentsData());
            dispatch(logoutUser());
            setSessionExpired(true);
        }
    }, [shouldLogout, t, dispatch]);

    const routeElement = useMemo(() => {
        if (isLoading || (isLoggedIn && !inboxData)) {
            return <Loader />;
        } else if (isLoggedIn && user) {
            return inboxData.length > 0 ? <Navigate to={`/inboxes/${inboxData[0].id}`} /> : <Home />;
        } else {
            return <Navigate to="/login" replace />;
        }
    }, [isLoading, isLoggedIn, user, inboxData]);



    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<RootLayout />}>
                <Route index element={routeElement} />
                <Route path="signup" element={<SignUp />} />
                <Route path="login" element={!isLoggedIn && !user ? <Login /> : <Navigate to="/" replace />} />
                <Route path="forgot-password" element={<ForgotPassword />} />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="email-sent" element={<EmailSent />} />
                <Route path="cookie-policy" element={<CookiePolicy />} />
                <Route path="*" element={<NotFound />} />
                <Route path="inboxes/:inboxId" element={isLoggedIn && user ? <FileUpload /> : <Navigate to="/login" replace />} />
                <Route path="inboxes/:inboxId/settings" element={isLoggedIn && user ? <InboxSettings /> : <Navigate to="/login" replace />} />
                <Route path="add-inbox" element={isLoggedIn && user ? <NewInbox setModalOn={setModalOn} /> : <Navigate to="/login" replace />} />
                <Route path="documents/:documentId" element={isLoggedIn && user ? <DataValidation /> : <Navigate to="/login" replace />} />
                <Route path="email-verification" element={<AccountVerified />} />
                <Route path="demo/:demoDocType" element={<DataValidationDemo />} />
                <Route path="settings/api-keys" element={isLoggedIn && user ? <APIKeys /> : <Navigate to="/login" replace />} />
            </Route>
        )
    );
    return (
        <ModalContext.Provider value={{ modalOn, setModalOn }}>
            {/* <Suspense fallback={<Loader />}> */}
            <RouterProvider router={router} />
            <ToastContainer />
            {/* </Suspense> */}
        </ModalContext.Provider>
    )
}

export default App
