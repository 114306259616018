import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'
import './index.css';
import { store } from './app/store.js';
import { Provider } from 'react-redux';
import './i18n';
import Intercom from '@intercom/messenger-js-sdk';

Intercom({
    app_id: 'lojpg5zj',
});

ReactDOM.createRoot(document.getElementById('root')).render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
)
