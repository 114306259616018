import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import '../../styles/ThumbnailsPreview.scss';
import { demoData } from '../demoData.js'


export default function ThumbnailsPreview() {
    const { demoDocType } = useParams();
    const [thumbnails, setThumbnails] = useState([]);
    const currentPreviewPage = 1

    useEffect(() => {
        if (demoDocType) {
            setThumbnails(demoData[demoDocType].thumbnails)
        }
    }, [demoDocType])


    return (
        <div className='thumbnails-pane' >
            <div className='thumbnails-container' >
                {thumbnails?.map((thumbnail, i) =>
                    <div className='thumbnail-container' key={i} style={currentPreviewPage === 1 ? { border: '2px solid #1877F2', backgroundColor: "#EFF8FD" } : { border: '1px solid transparent' }}>
                        <img src={thumbnail} className='thumbnail' alt='preview' />
                        <div className='thumbnail-page' style={{ textAlign: 'center', margin: '5px 0' }}>{i + 1}</div>
                    </div>
                )}
            </div>
        </div>
    )
}
