import '../styles/DragNDrop.scss';
import uploadSvg from "../assets/illustrations/upload.svg";
import { useTranslation } from "react-i18next";

export default function DragNDrop({ handleFileChange }) {

    const { t } = useTranslation(['translation', 'userManagement', 'errors']);

    return (
        <>
            <section className="drag-drop">
                <div className='document-uploader'>
                    <div className="upload-info">
                        <img className="uploadSvg" src={uploadSvg} alt="upload" />
                        <div className="upload-text-container">
                            <p className="upload-text">{t('fileUpload.uploadText')}</p>
                            <p className="file-type-text">{t('fileUpload.fileTypeText')}</p>
                        </div>
                        <input
                            type='file'
                            id='browse'
                            onChange={handleFileChange}
                            accept='.pdf, image/*'
                            multiple
                            style={{ position: 'absolute', opacity: 0, pointerEvents: 'none' }}
                        />
                        <label htmlFor='browse' className='browse-btn'>
                            {t('fileUpload.browseFile')} </label>
                    </div>
                </div>
            </section>
        </>
    )
}
