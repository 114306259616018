import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { hideInboxDeleteModal } from '../../features/modals/modalsSlice';

export default function ConfirmDeleteModal({ handleDelete, setConfirmDelete }) {
    const { t } = useTranslation();
    const { isInboxDeleteModalOpen } = useSelector(state => state.modals)
    const dispatch = useDispatch();

    return (
        <div className="modal-container modal-container__delete">
            {isInboxDeleteModalOpen ? <h3 >{t('confirmInboxDeletion')}</h3> : <h3>{t('confirmFieldGroupDeletion')}</h3>}
            <div className='button-container'>
                <button className="cancel-btn btn cancel-btn-modal" onClick={isInboxDeleteModalOpen ? () => dispatch(hideInboxDeleteModal()) : () => setConfirmDelete(false)}>{t('cancel')}</button>
                <button className="delete-btn btn" onClick={handleDelete}>{t('delete')}</button>
            </div>

        </div>
    )
}
