import { useState, useEffect } from 'react'
import Hero from '../components/Hero';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../styles/SignUp.scss';
import { Link } from 'react-router-dom';
import LoginButton from '../components/buttons/LoginButton';
import { reset, loginUser } from '../features/auth/authSlice';
import Loader from '../components/Loader';
import { ImEye, ImEyeBlocked } from "react-icons/im";
import { motion } from 'framer-motion';

export default function Login() {
    const { t } = useTranslation(['translation', 'userManagement']);

    //object containing the state of the form
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    })
    //destructuring the state object
    const { email, password } = formData;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, isLoading, isError, isSuccess, message } = useSelector(state => state.auth);
    const [isLoaderActive, setIsLoaderActive] = useState(false);

    //states and function for showing and hiding the password
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(ImEye);

    const handleShowPassword = () => {
        if (type === 'password') {
            setType('text');
            setIcon(ImEyeBlocked);
        } else {
            setType('password');
            setIcon(ImEye);
        }
    }

    useEffect(() => {
        if (isLoading) {
            setIsLoaderActive(true);
        } else if (isError) {
            if (message.includes('LOGIN_BAD_CREDENTIALS')) {
                setIsLoaderActive(false);
                toast.error(t('badCredentials', { ns: 'errors' }));
            } else {
                setIsLoaderActive(false);
                toast.error(t('loginFailed', { ns: 'errors' }));
            }
        } else if (isSuccess) {
            setIsLoaderActive(false);
        }
        dispatch(reset());
    }, [user, isLoading, isError, isSuccess, message, navigate, dispatch, t]);

    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (!email || !password) {
            toast.error(t('fillAllFields', { ns: 'errors' }));
            return;
        }
        const userData = { email, password };
        dispatch(loginUser(userData));
        setFormData({
            email: '',
            password: '',
        })
    }


    return (
        <main className='main-container main-login-container'>
            {isLoaderActive ? <Loader /> : (
                <>
                    <Hero />
                    <motion.form
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1.2, delay: 0.1 }}
                        className='login-form' onSubmit={onSubmit}>
                        <div className='login-inputs'>
                            <label className='login-label'>{t('email', { ns: 'userManagement' })}</label>
                            <input className='login-input' type='email' placeholder={t('enterEmail', { ns: 'userManagement' })} id='email' name='email' value={email} onChange={onChange} />
                        </div>
                        <div className='login-inputs password-container'>
                            <label className='login-label'>{t('password', { ns: 'userManagement' })}</label>
                            <input className='login-input password-input' type={type} placeholder={t('enterPassword', { ns: 'userManagement' })} id='password' name='password' value={password} onChange={onChange} />
                            {password && <span className='show-password-icon' onClick={handleShowPassword}>{icon}</span>}
                        </div>
                        <LoginButton />
                        <div className='link-container'>
                            <Trans i18nKey='userManagement:forgotPassword'><Link to='/forgot-password' className='forgot-password-link'>Forgot Password?</Link></Trans>
                            <div className='signup-container'>
                                <Trans i18nKey='userManagement:dontHaveAccount'>Don&apos;t have an account yet?<Link to='/signup' className='signup-link'>Sign Up</Link>
                                </Trans>
                            </div>
                        </div>
                    </motion.form>
                </>
            )}
        </main>
    )
}
