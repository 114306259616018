import { api } from './api';
import { toast } from 'react-toastify';

export const downloadCsv = async ({ documentId, documentsData, setShowMenu, t }) => {
    try {
        const response = await api.get(`/documents/${documentId}/extraction`, {
            headers: {
                Accept: 'text/csv',
            },
        });
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const selectedFile = documentsData.find(doc => doc.id === documentId);
        const fileNameWithoutExtension = selectedFile.name.replace(/\.[^/.]+$/, "");
        link.download = `${fileNameWithoutExtension}.csv`;
        link.click();
        URL.revokeObjectURL(url);
    } catch (err) {
        toast.error(t('downloadError', { ns: 'errors' }));
    }
    setShowMenu(false);
};
