import { api } from "../../api"

export const fetchUserCredits = async () => {
    const url = 'users/me/credits';
    const response = await api.get(url);
    return response.data;
}

export const fetchUserAPIKeys = async (limit = 100) => {
    const url = 'users/me/api-keys';
    const response = await api.get(url, limit);
    return response.data;
}

export const postNewAPIKey = async (APIKeyObject) => {
    const url = 'users/me/api-keys';
    const response = await api.post(url, APIKeyObject);
    return response.data;
}

export const deleteAPIKey = async (prefix) => {
    const url = `users/me/api-keys/${prefix}`;
    const response = await api.delete(url);
    return response.data;
}
