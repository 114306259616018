import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchPipelineInfo, patchPipelineTemplate } from "./pipelinesService";

const initialState = {
    pipelinesStatus: 'idle',
    pipelineMessage: '',
    pipelineTemplate: {},
    pipelineTemplateFields: [],
    pipelineInfo: {},
    isPipelineEdited: false,
}


export const getPipelineInfo = createAsyncThunk('pipelines/getPipelineInfo', async (pipelineId, thunkAPI) => {
    try {
        const response = await fetchPipelineInfo(pipelineId);
        return response;
    } catch (error) {
        if (error.response.data.detail) {
            const message = error.response.data.detail
            return thunkAPI.rejectWithValue(message);
        } else {
            const message = error.message
            return thunkAPI.rejectWithValue(message);
        }
    }
})

export const editPipelineTemplate = createAsyncThunk('pipelines/editPipelineInfo', async ({ pipelineId, newPipelineTemplate }, thunkAPI) => {
    try {
        const response = await patchPipelineTemplate(pipelineId, newPipelineTemplate);
        return response;
    } catch (error) {
        if (error.response.data.detail) {
            const message = error.response.data.detail
            return thunkAPI.rejectWithValue(message);
        } else {
            const message = error.message
            return thunkAPI.rejectWithValue(message);
        }
    }
})


const pipelinesSlice = createSlice({
    name: 'pipelines',
    initialState,
    reducers: {
        resetPipelineStatus: (state) => {
            state.pipelinesStatus = 'idle';
            state.pipelineMessage = '';
            state.isPipelineEdited = false;
        },
        resetPipelineTemplate: (state) => {
            state.pipelineTemplate = {};
            state.pipelineTemplateFields = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPipelineInfo.pending, (state) => {
                state.pipelinesStatus = 'loading';
                state.isPipelineEdited = false;
            })
            .addCase(getPipelineInfo.fulfilled, (state, action) => {
                state.pipelinesStatus = 'success';
                state.pipelineInfo = action.payload;
                state.pipelineTemplate = action.payload.template;
                state.pipelineTemplateFields = action.payload.template.fields;
                state.isPipelineEdited = false;
            })
            .addCase(getPipelineInfo.rejected, (state, action) => {
                state.pipelinesStatus = 'error';
                state.pipelineMessage = action.payload;
                state.isPipelineEdited = false;
            })

            .addCase(editPipelineTemplate.pending, (state) => {
                state.pipelinesStatus = 'loading';
                state.isPipelineEdited = false;
            })
            .addCase(editPipelineTemplate.fulfilled, (state) => {
                state.pipelinesStatus = 'success';
                state.isPipelineEdited = true;
            })
            .addCase(editPipelineTemplate.rejected, (state, action) => {
                state.pipelinesStatus = 'error';
                state.pipelineMessage = action.payload;
                state.isPipelineEdited = false;
            })

    }
})

export const { resetPipelineStatus, resetPipelineTemplate } = pipelinesSlice.actions;
export default pipelinesSlice.reducer;
