import { useTranslation } from 'react-i18next';
import '../../styles/Button.scss';

export default function LoginUpButton({ handleCancel }) {
    const { t } = useTranslation();

    return (
        <button onClick={handleCancel} className='cancel-btn btn'>{t('cancel')}</button>
    )
}
