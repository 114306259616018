import { useState, useEffect, useRef, useContext } from 'react';
import logo from '../assets/logo.png';
import { useTranslation } from 'react-i18next';
import { BiSolidDownArrow } from "react-icons/bi";
import LanguageSelector from './LanguageSelector';
import '../styles/Header.scss';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from '../features/auth/authSlice';
import { toast } from 'react-toastify';
import axios from 'axios';
import { MdAccountCircle } from "react-icons/md";
import { baseApi } from '../config';
import { resetInbox } from '../features/inboxes/inboxSlice';
import { resetDocumentExtraction, resetDocumentsData } from '../features/files/fileSlice';
import { ModalContext } from '../contexts/ModalContext';
import NavigationIcons from './NavigationIcons';
import BackToInbox from './BackToInbox';
import DemoSignUpButton from './buttons/DemoSignUpButton';



export default function Header() {
    const { t } = useTranslation();
    const { user, isError, isSuccess, message, isLoggedIn } = useSelector(state => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showMenu, setShowMenu] = useState(false);
    const [isBackArrowShown, setIsBackArrowShown] = useState(false);
    const location = useLocation();
    const userMenuRef = useRef();
    const arrowIconRef = useRef();
    const { modalOn } = useContext(ModalContext);
    const { documentInfo } = useSelector(state => state.documents);
    const [docName, setDocName] = useState('');
    const [showHeader, setShowHeader] = useState(true);
    const [isDemoPage, setIsDemoPage] = useState(false);

    useEffect(() => {
        if ((location.pathname.startsWith('/documents/') || location.pathname.includes('add-inbox')) || location.pathname.includes('email-sent') && user) {
            setIsBackArrowShown(true)
        }
        else {
            setIsBackArrowShown(false)
        }
    }, [location, user, setIsBackArrowShown, isBackArrowShown]);

    useEffect(() => {
        if (location.pathname.includes('add-inbox')) {
            setShowHeader(false)
        }
        else {
            setShowHeader(true)
        }
    }, [location, setShowHeader])

    useEffect(() => {
        if (location.pathname.includes('demo')) {
            setIsDemoPage(true)
        } else {
            setIsDemoPage(false)
        }
    }, [location])


    useEffect(() => {
        if (user) {
            localStorage.setItem('email', user)
        }

        const handleClickOutside = (e) => {
            if (showMenu && (userMenuRef.current && !userMenuRef.current.contains(e.target)) && (arrowIconRef.current && !arrowIconRef.current.contains(e.target))) {
                setShowMenu(false);
            }
        }
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        }
    }, [user, isError, isSuccess, message, navigate, dispatch, t, showMenu, setShowMenu]);

    const logout = () => {
        dispatch(resetInbox())
        dispatch(resetDocumentExtraction())
        dispatch(resetDocumentsData())
        dispatch(logoutUser());
        setShowMenu(false);
        navigate('/')
    }

    const resetPassword = async () => {
        const email = user
        try {
            await axios.post(`${baseApi}/auth/forgot-password`, { email },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
            navigate('/email-sent');
            setShowMenu(false);
        } catch (error) {
            if (error.response) {
                setShowMenu(false);
                toast.error(t('generalError', { ns: 'errors' }));
            }
        }
    }

    useEffect(() => {
        if (documentInfo && documentInfo.name) {
            setDocName(documentInfo.name)
        }
    }, [documentInfo]);



    return (
        <>
            {showHeader &&
                <header className={modalOn ? 'header-container header-container__modal-active' : 'header-container'}>
                    {!user && < Link to={isDemoPage ? 'https:smartextract.ai/' : '/'}><img src={logo} alt="logo" className='logo' /></Link>}
                    {isBackArrowShown &&
                        <div className='menu-logo-container' >
                            <BackToInbox />
                            {(!location.pathname.includes('add-inbox') && !location.pathname.includes('email-sent')) &&
                                <div className='doc-name-container'>
                                    <div className='doc-name-wrapper'>
                                        <h4 className='doc-name'>{docName}</h4>
                                    </div>
                                    <NavigationIcons />
                                </div>}
                        </div>
                    }
                    {isDemoPage ? null : <LanguageSelector />}
                    {isLoggedIn && (
                        <div
                            className='account-container'
                            onClick={() => setShowMenu(!showMenu)}
                            ref={arrowIconRef}
                        >
                            <h3 className='account'>{t('account')}</h3>
                            <MdAccountCircle className='account-icon' />
                            <BiSolidDownArrow className='user-down-arrow' />
                            {showMenu && (
                                <div className='dropdown-menu user-menu' ref={userMenuRef}>
                                    <button className='dropdown-menu-btn' onClick={resetPassword}>
                                        {t('resetPassword', { ns: 'userManagement' })}
                                    </button>
                                    <hr />
                                    <button className='dropdown-menu-btn' onClick={() => navigate('/settings/api-keys')}>
                                        {t('apiKeys', { ns: 'userManagement' })}
                                    </button>
                                    <hr />
                                    <button className='dropdown-menu-btn' onClick={logout}>
                                        {t('logout', { ns: 'userManagement' })}
                                    </button>
                                </div>
                            )}
                        </div>
                    )}
                    {isDemoPage && !user ? <DemoSignUpButton setIsDemoPage={setIsDemoPage} /> : null}
                </header>
            }
        </>
    )
}
