import { useState, useEffect } from 'react'
import Hero from '../components/Hero';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../styles/SignUp.scss';
import { Link } from 'react-router-dom';
import SignUpButton from '../components/buttons/SignUpButton';
import { reset, registerUser } from '../features/auth/authSlice';
import Loader from '../components/Loader';
import RegisterModal from '../components/modals/RegisterModal';
import { VscPass, VscError } from "react-icons/vsc";
import { ImEye, ImEyeBlocked } from "react-icons/im";
import { motion, AnimatePresence } from 'framer-motion';
import { createInboxData } from '../features/inboxes/inboxSlice';



export default function SignUp() {
    const { t } = useTranslation(['translation', 'userManagement', 'errors']);
    const [showModal, setShowModal] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    //password conditions states
    const [passwordLengthOK, setPasswordLengthOK] = useState(false);
    const [passwordUppercase, setPasswordUppercase] = useState(false);
    const [passwordLowercase, setPasswordLowercase] = useState(false);
    const [passwordNumber, setPasswordNumber] = useState(false);
    const [passwordSpecial, setPasswordSpecial] = useState(false);
    const [passwordSymbol, setPasswordSymbol] = useState(false);
    const [showRequirements, setShowRequirements] = useState(false);

    //states and function for showing and hiding the password
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(ImEye);

    const handleShowPassword = () => {
        if (type === 'password') {
            setType('text');
            setIcon(ImEyeBlocked);
        } else {
            setType('password');
            setIcon(ImEye);
        }
    }

    //object containing the state of the form
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })

    //destructuring the state object
    const { email, password } = formData;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user, isLoading, isError, isSuccess, message, isLoggedIn, isSignedUp } = useSelector(state => state.auth);


    useEffect(() => {
        if (isError && message === 'REGISTER_USER_ALREADY_EXISTS') {
            setShowLoader(false);
            toast.error(t('emailAlreadyRegistered', { ns: 'errors' }));
        } else if (isError && message.code === 'REGISTER_INVALID_PASSWORD') {
            setShowLoader(false);
            toast.error(t('invalidPassword', { ns: 'errors' }));
        } else if (isSuccess && user && isLoggedIn) {
            if (isSignedUp) {
                setShowLoader(false);
                setShowModal(true);
                // timer for hiding the modal after 3sec
                const hideModalTimeout = setTimeout(() => {
                    setShowModal(false);
                    navigate('/')
                }, 3000);
                return () => {
                    clearTimeout(hideModalTimeout);
                }
            }
        } else if (isLoading) {
            setShowLoader(true);
        }

        //password conditions
        const conditions = [passwordSymbol, passwordUppercase, passwordLowercase, passwordNumber, passwordSpecial];
        const trueConditions = conditions.filter(Boolean).length;

        if (!passwordLengthOK || trueConditions < 3) {
            setShowRequirements(true);
        } else {
            setShowRequirements(false);
        }
        dispatch(reset());
    }, [user, isLoading, isError, isSuccess, message, navigate, dispatch, t, isLoggedIn, isSignedUp, passwordLengthOK, passwordSymbol, passwordUppercase, passwordLowercase, passwordNumber, passwordSpecial]);



    const onChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        if (e.target.name === 'password') {
            setPasswordLengthOK(e.target.value.length >= 8);
            setPasswordUppercase(/[A-Z]/.test(e.target.value));
            setPasswordLowercase(/[a-z]/.test(e.target.value));
            setPasswordNumber(/[0-9]/.test(e.target.value));
            setPasswordSymbol(/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(e.target.value));
            setPasswordSpecial(/[^\w`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(e.target.value));
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        dispatch(createInboxData());
        if (!email || !password) {
            toast.error(t('fillAllFields', { ns: 'errors' }));
            return;
        } else {
            const userData = { email, password };
            dispatch(registerUser(userData));
            setFormData({
                email: '',
                password: '',
                confirmPassword: '',
            })
        }
    }

    const passwordRequirements = [
        { condition: passwordSymbol, text: t('passwordSymbol', { ns: 'errors' }) },
        { condition: passwordNumber, text: t('passwordNumber', { ns: 'errors' }) },
        { condition: passwordLowercase, text: t('passwordLowercase', { ns: 'errors' }) },
        { condition: passwordUppercase, text: t('passwordUppercase', { ns: 'errors' }) },
        { condition: passwordSpecial, text: t('passwordOtherCharacter', { ns: 'errors' }) },
    ]


    return (
        <main className='main-container'>
            <Hero />
            {showLoader && <Loader />}
            {!showModal && !showLoader && <>
                <motion.form
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1.2, delay: 0.1 }}
                    className='signup-form' onSubmit={onSubmit}>
                    <div className='signup-inputs'>
                        <label className='signup-label'>{t('email', { ns: 'userManagement' })}</label>
                        <input className='signup-input' type='email' placeholder={t('enterEmail', { ns: 'userManagement' })} id='email' name='email' value={email} onChange={onChange} />
                    </div>
                    <div className='signup-inputs password-container'>
                        <label className='signup-label'>{t('password', { ns: 'userManagement' })}</label>
                        <input className='signup-input password-input' type={type} placeholder={t('enterPassword', { ns: 'userManagement' })} id='password' name='password' value={password} onChange={onChange} autoComplete='new-password' />
                        {password && <span className='show-password-icon' onClick={handleShowPassword}>{icon}</span>}
                    </div>
                    <AnimatePresence>
                        {password && showRequirements && <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: .2 }}
                            exit={{ opacity: 0 }}
                            className='password-requirements'>
                            <p className='password-requirements-title'>{t('passwordRequirementsTitle', { ns: 'errors' })}</p>
                            <div className='password-requirements-wrapper'>
                                {passwordLengthOK ? <VscPass className='password-ok password-icon' /> : <VscError className='password-error password-icon' />}
                                <p className={passwordLengthOK ? 'password-requirements-ok' : 'password-requirements-error'}>{t('passwordLength', { ns: 'errors' })}</p>
                            </div>
                            <div className='password-requirements-wrapper'>
                                <p >{t('passwordMinRequirements', { ns: 'errors' })}</p>
                            </div>
                            <div className='password-min-requirements-wrapper'>
                                {passwordRequirements.map((requirement, index) => (
                                    <div key={index} className='password-requirements-wrapper'>
                                        {requirement.condition ? <VscPass className='password-ok password-icon' /> : <VscError className='password-error password-icon' />}
                                        <p className={requirement.condition ? 'password-requirements-ok' : 'password-requirements-error'}>{requirement.text}</p>
                                    </div>
                                ))}
                            </div>
                        </motion.div>
                        }
                    </AnimatePresence>
                    <SignUpButton />
                    <div>
                        <Trans i18nKey='userManagement:haveAccount'> Already have an account?<Link to='/login' className='login-link'>Log In</Link>
                        </Trans>
                    </div>
                    <div className='accept-terms'>
                        <Trans t={t} i18nKey='gdpr_policy_links'>By clicking Sign Up, you agree to our <a className='gdpr-link' href="https://smartextract.ai/agb">Terms</a> and confirm you have read and understood our <a className='gdpr-link' href="https://smartextract.ai/datenschutzerklarung">Privacy policy</a></Trans>
                    </div>
                </motion.form>
            </>}
            {showModal && <RegisterModal />}
        </main >
    )
}
