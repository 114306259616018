import React, { useEffect, useMemo, useState } from 'react';
import '../styles/AddNewField.scss'
import Select from 'react-select'
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';


export default function AddNewField({ isAddNewField, setIsAddNewField, onConfirm, isSubField, setIsEdited, scrollToCardRef, onCancel }) {

    const { t } = useTranslation(['editFieldMenu', 'translation']);
    const [newFieldName, setNewFieldName] = useState('');
    const translatedFieldTypes = useMemo(() => [t('text', { ns: 'editFieldMenu' }), t('multipleChoice', { ns: 'editFieldMenu' })], [t]);
    const [newFieldType, setNewFieldType] = useState("text");
    const [newFieldDescription, setNewFieldDescription] = useState('');
    const [isOpenNextInput, setIsOpenNextInput] = useState(false);
    const [multipleChoiceOptions, setMultipleChoiceOptions] = useState([]);
    const options = [
        { value: 'text', label: translatedFieldTypes[0] },
        { value: 'choice', label: translatedFieldTypes[1] },
    ]

    useEffect(() => {
        if (newFieldType === "choice") {
            setIsOpenNextInput(true)
        }
        else {
            setIsOpenNextInput(false)
        }
    }, [newFieldType]);

    const customStyles = {
        control: (baseStyles) => ({
            ...baseStyles,
            border: "none",
            boxShadow: 'none',
            '&:hover': {
                border: '1px solid #0F91D2',
            },
            height: "35px",
            fontWeight: "600",
            fontSize: "13px"
        }),
        option: (baseStyles, states) => ({
            ...baseStyles,
            fontSize: "13px",
            backgroundColor: states.isSelected ? "#C7DBFF" : null,
            color: states.isSelected ? "#434655" : "#424644",
            '&:hover': {
                backgroundColor: '#EFF8FD',
                color: '#434655'
            }
        }),
        menuPortal: (baseStyles) => ({ ...baseStyles, zIndex: 9999 }),
    }


    const fieldNameDescription = t('fieldNameDesc', { ns: 'editFieldMenu' });
    const fieldTypeDescription = t('fieldTypeDesc', { ns: 'editFieldMenu' });
    const fieldDescriptionDescription = t('fieldDescriptionDesc', { ns: 'editFieldMenu' });

    const addFieldsSteps = [
        {
            step: 1,
            title: t('fieldName', { ns: 'editFieldMenu' }),
            description: fieldNameDescription,
            input: <input
                type="text"
                placeholder={t('typeAnswer', { ns: 'editFieldMenu' })} className="add-field-input"
                onChange={(e) => setNewFieldName(e.target.value)}
                required={true}
                onKeyUp={(e) => e.key === 'Enter' ? handleConfirm() : null}
            />
        },
        {
            step: 2,
            title: t('fieldDescription', { ns: 'editFieldMenu' }),
            description: fieldDescriptionDescription,
            input: <input
                style={isSubField ? { display: 'auto' } : { display: 'none' }}
                type="text"
                placeholder={t('optional', { ns: 'editFieldMenu' })} className="add-field-input"
                onChange={(e) => setNewFieldDescription(e.target.value)} />
        },
        {
            step: 3,
            title: t('fieldType', { ns: 'editFieldMenu' }),
            description: fieldTypeDescription,
            input: <Select
                className="add-field-select"
                options={options}
                onChange={(e) => setNewFieldType(e.value)}
                value={options.find(option => option.value === newFieldType)}
                styles={customStyles}
                classNamePrefix={"options"}
                menuPortalTarget={document.body} />
        }
    ];



    const handleConfirm = () => {
        if (!newFieldName || !newFieldType) {
            toast.error(t('fillAllFields', { ns: 'errors' }));
            return;
        }
        const newField = {
            name: newFieldName,
            description: newFieldDescription,
            type: newFieldType,
            multiple: false,
        };
        if (multipleChoiceOptions.length > 0) {
            newField.choices = multipleChoiceOptions;
        }
        onConfirm(newField);
        setNewFieldDescription("");
        setNewFieldName("");
        setNewFieldType("text");
        setIsAddNewField(false);
        setIsEdited(true);
        setMultipleChoiceOptions([]);
    }

    const handleChoices = (e) => {
        const choices = e.target.value.split(',');
        const newOptions = choices.map(choice => ({
            name: choice.trim(),
            description: null
        }));
        setMultipleChoiceOptions(newOptions);
    }
    return (
        <>
            {isAddNewField &&
                <div className="add-field-card" ref={scrollToCardRef}>
                    <div className='add-field-wrapper'>
                        {addFieldsSteps.map((step, idx) => {
                            return (
                                <div className='add-field-input-container' key={idx}>
                                    <p className="add-field-desc">{step.description}</p>
                                    {step.input}
                                </div>
                            )
                        })}
                        {isOpenNextInput && <div className='add-field-input-container'>
                            <p className="add-field-desc">{t('writeChoices', { ns: 'editFieldMenu' })} </p>
                            <input type="text" placeholder={t('choices', { ns: 'editFieldMenu' })} className="add-field-input" onChange={(e) => { handleChoices(e) }} />
                        </div>}
                    </div>
                    <div className="buttons-container">
                        <button className='cancel-button' onClick={onCancel}>{t('cancel', { ns: 'translation' })}</button>
                        <button className='confirm-btn btn' onClick={handleConfirm} disabled={!newFieldName}>{t('confirm', { ns: 'editFieldMenu' })}</button>
                    </div>
                </div>
            }
        </>
    )
}
