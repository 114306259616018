import { uploadDocument, resetDocumentsStates, getAllDocuments } from './features/files/fileSlice';
import { getInboxInfo } from './features/inboxes/inboxSlice';
import { documentLimit } from './config';
import { toast } from 'react-toastify';

export const handleFileUpload = async (files, inboxId, dispatch, setShowUploadModal, t, offset) => {
    const formData = new FormData();
    Array.from(files).forEach((file) => {
        formData.append('document', file);
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = async () => {
            try {
                const isUploaded = await dispatch(uploadDocument({ inboxId: inboxId, document: file })).unwrap();
                setShowUploadModal(false);
                if (isUploaded) {
                    dispatch(getAllDocuments({ inboxID: inboxId, offset: offset }));
                    dispatch(getInboxInfo({ limit: documentLimit, offset: 0 }));
                    dispatch(resetDocumentsStates());
                }
            } catch (error) {
                toast.error(t('uploadError', { ns: 'errors' }), { toastId: 'uploadErrorToast' });
                setShowUploadModal(false);
            }
        };
    });
};
