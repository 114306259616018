import { useTranslation } from 'react-i18next';
import '../../styles/Icons.scss';
import { RiUpload2Fill } from "react-icons/ri";
import { useRef } from 'react';


export default function UploadButton({ handleFileChange }) {
    const { t } = useTranslation();
    const fileInputRef = useRef(null);


    return (
        <div className='icon-container'>
            <RiUpload2Fill onClick={() => fileInputRef.current.click()} className="upload-icon icon" title={t('uploadDocument')} />
            <input
                type='file'
                ref={fileInputRef}
                onChange={handleFileChange}
                accept='.pdf, image/*'
                multiple
                style={{ display: 'none' }}
            />
        </div>
    );
}
