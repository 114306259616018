import gdpr from '../assets/gdprCompliant.png';
import logo from '../assets/logo.png';
import { useTranslation } from 'react-i18next';
import '../styles/Footer.scss';

export default function Footer({ modalOn }) {
    const { t } = useTranslation('footer');
    return (
        <footer className={modalOn ? 'footer-container footer-container__modal-active' : 'footer-container'}>
            <img src={gdpr} alt="gdpr" className='gdpr-image' />
            <a className='logo' href="https://smartextract.ai"><img src={logo} alt="logo" className='logo'/></a>
            <a className='footer-links' href="https://smartextract.ai/impressum">{t("impressum")}</a>
            <a className='footer-links' href="https://smartextract.ai/agb">{t("terms")}</a>
            <a className='footer-links' href="https://smartextract.ai/datenschutzerklarung">{t("privacyPolicy")}</a>
            <a className='footer-links' href="">{t("contact")}</a>
        </footer>
    )
}
