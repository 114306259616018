import React from 'react';
import { api } from '../../api';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import '../../styles/Button.scss';

export default function JSONButton({ selectedFiles, setShowDownloadMenu, selectAll }) {

    const { inboxId } = useParams();
    const { inboxData } = useSelector(state => state.inbox);
    const { documentsData } = useSelector(state => state.documents);
    const { t } = useTranslation();

    const downloadFile = (data, fileName) => {
        const blob = new Blob([JSON.stringify(data, null, 4)], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(url);
    };

    const handleDownloadSingleJson = async () => {
        const fileId = Object.keys(selectedFiles).find(key => selectedFiles[key]);
        if (fileId) {
            const docId = fileId;
            try {
                const response = await api.get(`/documents/${docId}/extraction`, {
                    withCredentials: true,
                });
                const selectedFile = documentsData.find(doc => doc.id === docId);
                const fileNameWithoutExtension = selectedFile.name.replace(/\.[^/.]+$/, "");
                downloadFile(response.data, `${fileNameWithoutExtension}.json`);
            } catch (err) {
                toast.error(t('downloadError', { ns: 'errors' }));
            }
        }
        setShowDownloadMenu(false);
    };

    const handleDownloadExtractions = async () => {
        let allData = [];
        let docIds = [];
        for (const fileId in selectedFiles) {
            if (selectedFiles[fileId]) {
                docIds.push(`id=${fileId}`);
            }
        }
        const docIdsQuery = docIds.join('&');
        try {
            const response = await api.get(`/inboxes/${inboxId}/extractions?order_by=date:desc&${docIdsQuery}`, {
                headers: {
                    Accept: 'application/json',
                },
                withCredentials: true,
            });
            allData.push(response.data);
            const now = new Date();
            const localeString = now.toLocaleString().replace(/:/g, '-').replace(/,/g, '').replace(/\s/g, '_');
            downloadFile(allData, `extracted_data_${localeString}.json`);
        } catch (err) {
            toast.error(t('downloadError', { ns: 'errors' }));
        }
        setShowDownloadMenu(false);
    };

    const handleDownloadAllExtractions = async () => {
        const inbox = inboxData.find(inbox => inbox.id === inboxId);
        const fileName = inbox?.name + '_' + new Date().toISOString().slice(0, 16);
        try {
            const response = await api.get(`/inboxes/${inboxId}/extractions?order_by=date:desc&limit=1000`, {
                headers: {
                    Accept: 'application/json',
                },
                withCredentials: true,
            });
            const results = response.data.results;
            downloadFile(results, `${fileName}.json`);
        } catch (err) {
            toast.error(t('downloadError', { ns: 'errors' }));
        }
        setShowDownloadMenu(false);
    };

    return (
        <>
            <button
                className='dropdown-menu-btn'
                onClick={() => {
                    const selectedFileCount = Object.values(selectedFiles).filter(Boolean).length;
                    selectAll ? handleDownloadAllExtractions() : (selectedFileCount === 1 ? handleDownloadSingleJson() : handleDownloadExtractions());
                }}>
                JSON
            </button>
        </>
    );
}
