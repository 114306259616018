import React, { useState } from 'react'
import BackButton from '../components/buttons/BackButton'
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import APIKeysList from '../components/APIKeysList';
import CreateAPIKey from '../components/CreateAPIKey';
import ShowAPIKey from '../components/ShowAPIKey';

export default function APIKeys() {
    const { t } = useTranslation('userManagement');
    // false when listing API keys, or true when creating a new one,
    // or a promise resolving to the API key creation response when
    // displaying a new API key.
    const [newAPIKey, setNewAPIKey] = useState(false);

    return (
        <div className='settings-main-container'>
            <BackButton />
            <div className='settings-title-container settings-title-container__api'>

                <h2 className='settings-title settings-title__api'>{t('APIKeysTitle')}</h2>
                <div className='setting-text-container'>
                    <Trans i18nKey='APIKeysText1' ns='userManagement'>
                        API keys are used to integrate smartextract with your application via our REST API. See the <Link to='https://docs.smartextract.ai/' className='settings-link settings-link__api'> developer guide</Link> for details.
                    </Trans>
                </div>
                <p className='settings-text settings-text__api'>{t('APIKeysText2')}</p>
            </div>
            <div className='settings-container settings-container__api'>
                <div className="settings-subtitle-action-container">
                    <h3 className='settings-subtitle settings-subtitle__api'>
                        {newAPIKey ? t('addNewAPIKey') : t('activeAPIKeys')}
                    </h3>
                    {!newAPIKey &&
                        <button
                            className='btn settings-btn settings-btn__api'
                            onClick={() => setNewAPIKey(true)}
                        >
                            {t('createAPIKey')}
                        </button>
                    }
                </div>
                <div className='divider'></div>
                {newAPIKey === true
                    ? <CreateAPIKey setNewAPIKey={setNewAPIKey} />
                    : newAPIKey
                        ? <ShowAPIKey newAPIKey={newAPIKey} setNewAPIKey={setNewAPIKey} />
                        : <APIKeysList />}
            </div>
        </div>
    )
}
