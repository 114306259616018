import { useEffect, useState } from 'react';

export function useInboxName(inboxId, inboxData) {
    const [inboxName, setInboxName] = useState('');

    useEffect(() => {
        if (inboxData?.length > 0) {
            const inbox = inboxData?.find(inbox => inbox.id === inboxId);
            if (inbox) {
                setInboxName(inbox.name);
            }
        }
    }, [inboxData, inboxId])

    return inboxName;
}
