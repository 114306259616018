import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiDownload2Fill } from "react-icons/ri";
import '../../styles/Button.scss';
import CSVButton from './CSVButton';
import JSONButton from './JSONButton';

export default function DownloadButton({ selectedFiles, disabledButton, selectAll }) {
    const [showDownloadMenu, setShowDownloadMenu] = useState(false);
    const { t } = useTranslation();
    const menuRef = useRef(null);
    const iconRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (showDownloadMenu && menuRef.current && !menuRef.current.contains(e.target) && iconRef.current && !iconRef.current.contains(e.target)) {
                setShowDownloadMenu(false);
            }
        }
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        }
    }, [showDownloadMenu]);

    return (
        <div className={disabledButton ? "disabled-container" : 'download-btn-container'}>
            <div className={disabledButton ? " disabled-icon" : "download-icon icon"} ref={iconRef}>
                <RiDownload2Fill
                    onClick={() => setShowDownloadMenu(!showDownloadMenu)}
                    className={disabledButton ? " disabled-icon" : "download-icon icon"}
                    title={t('downloadDocument')}
                />
            </div>
            {showDownloadMenu && (
                <div className='dropdown-menu download-menu' ref={menuRef}>
                    <JSONButton setShowDownloadMenu={setShowDownloadMenu} selectAll={selectAll} selectedFiles={selectedFiles} />
                    <CSVButton setShowDownloadMenu={setShowDownloadMenu} selectAll={selectAll} selectedFiles={selectedFiles} />
                </div>
            )}
        </div>
    )
}
