import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import fileReducer from "../features/files/fileSlice";
import inboxReducer from "../features/inboxes/inboxSlice";
import userReducer from "../features/user/userSlice";
import templateReducer from "../features/templates/templatesSlice";
import pipelineReducer from "../features/pipelines/pipelinesSlice";
import modalReducer from "../features/modals/modalsSlice";

export const store = configureStore({
    reducer: {
        auth: authReducer,
        documents: fileReducer,
        inbox: inboxReducer,
        user: userReducer,
        templates: templateReducer,
        pipelines: pipelineReducer,
        modals: modalReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }),
})
