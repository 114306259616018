import "../styles/LegalPages.scss";
import { useTranslation, Trans } from "react-i18next";
import { Link } from "react-router-dom";


export default function CookiePolicy() {

  const { t } = useTranslation('cookiePolicy');

  return (
    <main className="cookie-policy-main-container legal-pages">
      <div className="legal-page-container">
        <h1 className="cookie-policy-title">{t('cookieTitle')}</h1>
        <Trans i18nKey="cookiePolicy.firstParagraph"><p>This cookie policy (“Policy”) describes what cookies are and how they&apos;re being used by the{' '}
          <Link to="https://www.smartextract.ai">smartextract.ai</Link> website (“Website” or “Service”) and any of its related products and services (collectively, “Services”). This Policy is a legally binding agreement between you (“User”, “you” or “your”) and dida Datenschmiede GmbH (“dida Datenschmiede GmbH”, “we”, “us” or “our”). If you are entering into this Policy on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this Policy, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this Policy, you must not accept this Policy and may not access and use the Website and Services. You should read this Policy so you can understand the types of cookies we use, the information we collect using cookies and how that information is used. It also describes the choices available to you regarding accepting or declining the use of cookies.</p></Trans>
        <div className="content-wrapper">
          <h3>{t('tableOfContents')}</h3>
          <ol className="content-wrapper">
            <Trans i18nKey="cookiePolicy.1"><li><Link to="#what-are-cookies">What are cookies?</Link></li></Trans>
            <Trans i18nKey="cookiePolicy.2"><li><Link to="#what-type-of-cookies-do-we-use">What type of cookies do we use?</Link></li></Trans>
            <Trans i18nKey="cookiePolicy.3"><li><Link to="#what-are-your-cookie-options">What are your cookie options?</Link></li></Trans>
            <Trans i18nKey="cookiePolicy.4"><li><Link to="#changes-and-amendments">Changes and amendments</Link></li></Trans>
            <Trans i18nKey="cookiePolicy.5"><li><Link to="#acceptance-of-this-policy">Acceptance of this policy</Link></li></Trans>
            <Trans i18nKey="cookiePolicy.6"><li><Link to="#contacting-us">Contacting us</Link></li></Trans>
          </ol>
        </div>
        <h2 id="what-are-cookies">{t('WhatAreCookies')}</h2>
        <p>{t('WhatAreCookiesParragraph1')}</p>
        <p>{t('WhatAreCookiesParragraph2')}</p>
        <p>{t('WhatAreCookiesParragraph3')} </p>
        <p>{t('WhatAreCookiesParragraph4')}</p>
        <h2 id="what-type-of-cookies-do-we-use">{t('WhatTypeOfCookies')}</h2>
        <h3>{t('NecessaryCookies')}</h3>
        <p>{t('NecessaryCookiesParagraph')}</p>
        <h3>{t('FunctionalCookies')}</h3>
        <p>{t('FunctionalCookiesParagraph')}</p>
        <h2 id="what-are-your-cookie-options">{t('WhatCookieOptions')}</h2>
        <Trans i18nKey="WhatCookieOptionsParagraph1"><p>If you don&apos;t like the idea of cookies or certain types of cookies, you can change your browser&apos;s settings to delete cookies that have already been set and to not accept new cookies. Visit{' '}<Link to="https://www.internetcookies.com" target="_blank" rel="nofollow noreferrer noopener external">internetcookies.com</Link>{' '}to learn more about how to do this.</p></Trans>
        <p>{t('WhatCookieOptionsParagraph2')}</p>
        <h2 id="changes-and-amendments">{t('ChangesAndAmendments')}</h2>
        <p>{t('ChangesAndAmendmentsParagraph1')}</p>
        <p>{t('ChangesAndAmendmentsParagraph2')}</p>
        <h2 id="acceptance-of-this-policy">{t('AcceptanceOfThisPolicy')}</h2>
        <p>{t('AcceptanceParagraph')}</p>
        <h2 id="contacting-us">{t('ContactingUs')}</h2>
        <p>{t('ContactUsParragraph')}</p>
        <Trans i18nKey='emailLink'> <p><Link to="mailto:sup&#112;&#111;&#114;&#116;&#64;sma&#114;&#116;e&#120;tra&#99;&#116;&#46;ai">sup&#112;&#111;&#114;&#116;&#64;sma&#114;&#116;e&#120;tra&#99;&#116;&#46;ai</Link></p></Trans>

        <h5>{t('lastUpdated')}</h5>
        <p className="madewith">
          <a href="https://www.websitepolicies.com/cookie-policy-generator?via=madewithbadge" target="_blank" rel="noreferrer"><img
            width="200"
            height="25"
            alt="Made with WebsitePolicies cookie policy generator"
            src="https://cdn.websitepolicies.io/img/badge.png"
            srcSet="https://cdn.websitepolicies.io/img/badge_2x.png 2x"
          /></a></p>
      </div>
    </main>
  )
}
