import { IoIosArrowBack } from "react-icons/io";
import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import '../styles/BackToInbox.scss'

export default function BackToInbox() {
    const navigate = useNavigate();
    const inboxId = useSelector(state => state.documents.documentInfo.inbox_id);
    const { t } = useTranslation();

    const handleBack = (inboxId) => {
        inboxId ? navigate(`/inboxes/${inboxId}`) : navigate('/')
    }
    return (
        <div className='back-container' onClick={() => handleBack(inboxId)}>
            <IoIosArrowBack className='back-icon' />
            <div className="back-tooltip">{t('backToInbox')}</div>
        </div>
    )
}
