import '../styles/Home.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function Home() {
    const { t } = useTranslation()
    const navigate = useNavigate();

    const goToAddInbox = () => {
        navigate('/add-inbox');
    }

    return (
        <main className='home-container'>
            <div className='welcome-container'>
                <h1 className='welcome-title'>{t('welcome')}</h1>
                <div className='welcome-step'>
                    <h4 className='welcome-step-title'>{t('createFirstInbox')}</h4>
                    <p className='welcome-step-text'>{t('createFirstInboxText')}</p>
                </div>
                <div className='welcome-step'>
                    <h4 className='welcome-step-title'>{t('importDocuments')}</h4>
                    <p className='welcome-step-text'>{t('importDocumentsText')}</p>
                </div>
                <div className='welcome-step'>
                    <h4 className='welcome-step-title'>{t('customizeOutOfBox')}</h4>
                    <p className='welcome-step-text'>{t('customizeOutOfBoxText')}</p>
                </div>
                <button className='create-btn btn' onClick={goToAddInbox}>{t('createButton')}</button>
            </div>
        </main>
    )
}
