import { useTranslation } from 'react-i18next';
import '../../styles/Button.scss';

export default function SaveButton({ handleSave, ...props }) {
    const { t } = useTranslation();


    return (
        <button onClick={() => handleSave(props)} className='save-btn btn'>{t('save')}</button>
    )
}
