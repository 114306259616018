import escapeStringRegexp from 'escape-string-regexp';

/**
 * Class representing OCR information of a page.
 */
export class Page {
    constructor(words, boxes, width, height) {
        this.words = words
        this.boxes = boxes
        this.width = width
        this.height = height
    }

    /**
     * The list of boxes of the page, with all coordinates normalized in
     * the 0..1 range.
     */
    get normalizedBoxes() {
        let w = this.width, h = this.height
        return this.boxes.map(b => normalizeBox(b, w, h))
    }

    /**
     * The text of the page, as a single line.
     */
    get text() {
        if (!this._text) {
            this._text = this.words ? this.words.join(" ") : '';
        }
        return this._text;
    }

    /**
     * An array where each entry indicates which word the corresponding
     * character of Page.text belongs to.
     *
     * Spaces in the page text are assigned the index null.
     */
    get indices() {
        if (!this._indices) {
            const last = this.words.length - 1
            this._indices = this.words.flatMap((w, i) => {
                const indices = new Array(w.length).fill(i)
                if (i !== last) { indices.push(null) }
                return indices
            })
        }
        return this._indices
    }

    /**
     * Array of boxes for each character of the word at index i.
     *
     * Note: this is naive; it doesn't take graphemes of varying glyph
     * widths into account.
     */
    getCharBoxes(i) {
        const word = this.words[i]
        if (word.length === 0) {
            return []
        }
        const [x0, y0, x1, y1] = this.boxes[i]
        const dx = (x1 - x0) / word.length
        const result = []
        for (let j = 0; j < word.length; j++) {
            result.push([x0 + j * dx, y0, x0 + (j + 1) * dx, y1])
        }
        return result
    }

    /**
     * Array of boxes, one for each character of `Page.text`.
     *
     * To spaces in the page text corresponds a null value instead of a
     * box.
     */
    get subBoxes() {
        if (!this._subBoxes) {
            const last = this.words.length - 1
            this._subBoxes = this.words.flatMap((_, i) => {
                const boxes = this.getCharBoxes(i)
                if (i !== last) { boxes.push(null) }
                return boxes
            })
        }
        return this._subBoxes
    }


    /**
     * Search for a match of `query` string in the page text.
     *
     * If found, return the start index and the matched portion of the
     * text.  Otherwise return null.
     */
    search(query, options = {}) {
        const re = makeRegExp(query, false, options)
        if (!re) { return null }
        const m = re.exec(this.text)
        return m && [m.index, m[0]]
    }

    /**
     * Search for all matches of `query` string in the page text.
     *
     * Return an array (possibly empty) of pairs [index, text].
     */
    searchAll(query, options = {}) {
        const re = makeRegExp(query, true, options)
        if (!re) { return [] }
        return Array.from(this.text.matchAll(re), (m) => [m.index, m[0]])
    }

    /**
     * Return an array of boxes of the page corresponding to the first
     * match of the given text.
     *
     * If no match is found, return null.
     *
     * The second argument may contain the following options:
     * - Any `makeRegExp` options (see below).
     * - agglutinate (boolean, default true): If true, agglutinate the
     *   returned boxes.
     * - normalize (boolean, default true): If true, normalize boxes
     *   to have coordinates in the 0..1 range.
     */
    findMatchingBoxes(query, options = {}) {
        const matches = this.searchAll(query, options)
        let boxes = matches.flatMap(
            m => this.subBoxes.slice(m[0], m[0] + m[1].length).filter(Boolean)
        )
        const { agglutinate = true, normalize = true } = options
        if (agglutinate) {
            boxes = agglutinateBoxes(boxes)
        }
        if (normalize) {
            boxes = boxes.map(box => normalizeBox(box, this.width, this.height))
        }
        return boxes
    }
}

/**
 * Return a regexp matching the `query` string ignoring punctuation and spaces.
 *
 * If query doesn't contain any alphanumeric characters, return null.
 *
 * The second argument sets the global flag of the regexp.  The third
 * argument may contain the following options:
 * - `fuzzy` (boolean, default true): Ignore punctuation and spaces.
 * - `ignoreCase` (boolean, default true): Ignore case.
 * - `wholeWords` (boolean, default true): Match whole words only.
 */
function makeRegExp(query, global, options) {
    const { fuzzy = true, ignoreCase = true, wholeWords = true } = options
    const flags = "u" + (ignoreCase ? "i" : "") + (global ? "g" : "")
    let re
    if (fuzzy) {
        // Match all letters, marks (combining accents) and numbers.
        const chars = Array.from(query.matchAll(/[\p{L}\p{M}\p{N}]/gu), v => v[0])
        if (chars.length === 0) { return null }
        re = chars.join("[^\\p{L}\\p{N}]*")
    } else {
        if (query === "") { return null }
        re = escapeStringRegexp(query)
    }
    if (wholeWords) {
        re = `\\b${re}\\b`
    }
    return new RegExp(re, flags)
}

/**
 * Return a new box with coordinates in units of dx and dy.
 */
export function normalizeBox(box, dx, dy) {
    return [box[0] / dx, box[1] / dy, box[2] / dx, box[3] / dy]
}

/**
 * Return the area of the box.
 */
function boxArea(box) {
    return (box[2] - box[0]) * (box[3] - box[1])
}

/**
 * Compute the intersection of two boxes.
 *
 * If they don't intersect, return null.  Otherwise, return the
 * largest box that fits inside both argument boxes.
 */
function boxIntersection(box1, box2) {
    if (box1[0] < box2[2]
        && box1[1] < box2[3]
        && box1[2] > box2[0]
        && box1[3] > box2[1]
    ) {
        return [
            Math.max(box1[0], box2[0]),
            Math.max(box1[1], box2[1]),
            Math.min(box1[2], box2[2]),
            Math.min(box1[3], box2[3])
        ]
    } else {
        return null
    }
}

/**
 * The fraction of the area of `box` covered by `boxes`.
 */
function boxOverlap(box, ...boxes) {
    let area = boxArea(box)
    if (area <= 0) { return 0 }
    boxes = boxes.map(b => boxIntersection(box, b))
    let overlap = boxes.reduce((area, box) => box ? area + boxArea(box) : area, 0)
    return overlap / boxArea(box)
}

/**
 * Return the smallest box fitting a list of boxes.
 */
export function mergeBoxes(...boxes) {
    return [
        Math.min(...boxes.map(b => b[0])),
        Math.min(...boxes.map(b => b[1])),
        Math.max(...boxes.map(b => b[2])),
        Math.max(...boxes.map(b => b[3])),
    ]
}

/**
 * Merge some boxes if they fit together sufficiently nicely.
 *
 * Subsequences of boxes are merged into a single box as long as the
 * ratio between the area of the original boxes and the area of the
 * merged box doesn't fall below the given threshold.
 *
 * The return value is again an array of boxes, possibly shorter than
 * the input array.
 */
export function agglutinateBoxes(boxes, threshold = 0.5) {
    if (boxes.length === 0) { return [] }
    let currentGroup = null
    const groups = []
    for (const box of boxes) {
        if (currentGroup === null) { // First iteration only, initialize currentGroup
            currentGroup = [box]
            groups.push(currentGroup)
            continue
        }
        let previousBox = currentGroup[currentGroup.length - 1]
        let merged = mergeBoxes(box, previousBox)
        if (boxOverlap(merged, box, previousBox) >= threshold) {
            currentGroup.push(box)
        } else {
            currentGroup = [box]
            groups.push(currentGroup)
        }
    }
    return groups.map(bs => mergeBoxes(...bs))
}
