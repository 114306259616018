import error from '../../assets/errorIcon.svg';
import { useTranslation } from 'react-i18next';
import '../../styles/Modals.scss';

export default function EmailAlreadyVerifiedErrorModal() {
    const { t } = useTranslation('errors');
    return (
        <div className="modal-container modal-container__verified">
            <img src={error} alt="error icon" className='error-icon' />
            <h3>{t('ops')}</h3>
            <h4>{t('wrong')}</h4>
            <p className='error '>{t('emailAlreadyVerified')}</p>
        </div>
    )
}
