import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EmailVerifiedModal from '../components/modals/EmailVerifiedModal';
import ExpiredTokenErrorModal from '../components/modals/ExpiredTokenErrorModal';
import EmailAlreadyVerifiedErrorModal from '../components/modals/EmailAlreadyVerifiedErrorModal';
import { useDispatch, useSelector } from 'react-redux';
import { resetVerificationState, verifyAccount } from '../features/auth/authSlice';

export default function AccountVerified() {
    const [isSuccess, setIsSuccess] = useState(false);
    const [isAlreadyVerified, setIsAlreadyVerified] = useState(false);
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const { isError, message, isVerified } = useSelector(state => state.auth);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token) {
            dispatch(verifyAccount(token));
        } else {
            navigate('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let timeoutId;
        if (isVerified) {
            setIsSuccess(true);
            setIsAlreadyVerified(false);
            setIsTokenExpired(false);
            timeoutId = setTimeout(() => {
                setIsSuccess(false);
                dispatch(resetVerificationState());
                navigate('/');
            }, 3000);
        } else if (isError && message === "VERIFY_USER_BAD_TOKEN") {
            setIsTokenExpired(true);
            timeoutId = setTimeout(() => {
                setIsTokenExpired(false);
                dispatch(resetVerificationState());
                navigate('/');
            }, 5000);
        } else if (isError && message === "VERIFY_USER_ALREADY_VERIFIED") {
            setIsAlreadyVerified(true);
            timeoutId = setTimeout(() => {
                setIsAlreadyVerified(false);
                dispatch(resetVerificationState());
                navigate('/');
            }, 3000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [isVerified, isError, message, navigate, dispatch]);

    return (
        <main className='main-container'>
            {isSuccess && <EmailVerifiedModal />}
            {isAlreadyVerified && <EmailAlreadyVerifiedErrorModal />}
            {isTokenExpired && <ExpiredTokenErrorModal />}
        </main>
    )
}
