import React from 'react'
import { MdCloudUpload } from "react-icons/md";
import { useTranslation } from 'react-i18next';
import '../styles/DragNDropInfo.scss';


export default function DragNDropInfo({ handleDrag, setIsDragging }) {
    const { t } = useTranslation();
    return (
        <div className="drag-drop-overlay"
            onDragOver={handleDrag}
            onDragEnter={handleDrag}
            onDragLeave={() => setIsDragging(false)}>
            <MdCloudUpload className='drag-drop-icon' />
            <p className="drag-drop-text">{t('dragDropText')}</p>
        </div>
    )
}
