import { useTranslation } from 'react-i18next';
import '../../styles/Button.scss';

export default function LoginButton() {
    const { t } = useTranslation(['translation', 'userManagement']);

    return (
        <button type='submit' className='login-btn btn'>{t('login', { ns: 'userManagement' })}</button>
    )
}
