
import error from "../assets/illustrations/404Error.svg"


export default function NotFound() {
    return (
        <main className="main-container">
            <img src={error} alt="404 not found" />
        </main>
    )
}
