import { MdArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import '../styles/NavigationIcons.scss'
import { useEffect } from "react";
import { getAllDocuments, hideBoundingBoxes, hideThumbnails, resetPageCount } from "../features/files/fileSlice";


export default function NavigationIcons() {

    const { documentsCount, documentsData } = useSelector(state => state.documents);
    const navigate = useNavigate();
    const { documentId } = useParams();
    const documentIndex = documentsData.findIndex(document => document.id === documentId);
    const dispatch = useDispatch();
    const inboxId = useSelector(state => state.documents.documentInfo.inbox_id);

    useEffect(() => {
        if (inboxId) {
            dispatch(getAllDocuments({ inboxID: inboxId, offset: 0, limit: 1000 }));
        }
    }, [inboxId]) // eslint-disable-line react-hooks/exhaustive-deps


    const jumpToDocument = (i) => (
        () => {
            const nextDocument = documentsData?.[documentIndex + i]
            if (nextDocument) {
                navigate(`/documents/${nextDocument.id}`);
                dispatch(hideThumbnails())
                dispatch(hideBoundingBoxes())
                dispatch(resetPageCount())
            }
        });

    return (
        <div className="navigation-container">
            <MdArrowBackIos
                onClick={jumpToDocument(-1)}
                style={documentIndex <= 0
                    ? { display: 'none' }
                    : { display: 'block', cursor: 'pointer' }
                }
                className='navigation-icon'
            />
            <p>{documentIndex + 1} / {documentsCount}</p>
            <MdArrowForwardIos
                onClick={jumpToDocument(1)}
                style={documentIndex < (documentsData.length - 1)
                    ? { display: 'block', cursor: 'pointer' }
                    : { display: 'none' }
                }
                className='navigation-icon' />
        </div>
    )
}
