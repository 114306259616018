import React from 'react'
import { useTranslation } from 'react-i18next';
import '../styles/APIDocumentation.scss';
import { Link } from 'react-router-dom';

export default function APIDocumentation() {
    const { t } = useTranslation('generalSettings')
    return (
        <div className='api-access-container'>
            <h3 className='inbox-settings-subtitle'>{t('APIDocumentationTitle')}</h3>
            <p className='api-integration-hub-text'>{t('APIDocumentationSubtitle')} </p>
            <p className='api-integration-hub-text'>
                {t('APIDocumentationText')}
            </p>
            <Link to="https://api.smartextract.ai/docs" className='api-integration-hub-link'>{t('APIDocumentationLink')}</Link>
        </div>
    )
}
