import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../styles/Modals.scss';


export default function EditNameModal({
    setIsEditingName,
    newFieldName,
    setNewFieldName,
    setIsFieldNameEdited,
    setIsEdited,
    subFieldBeingEdited,
    setNewSubfieldName,
    setIsSubfieldNameEdited
}) {
    const [newNameValue, setNewNameValue] = useState(newFieldName);
    const [newSubfieldNameValue, setNewSubfieldNameValue] = useState(subFieldBeingEdited?.name);
    const { t } = useTranslation();

    const handleConfirm = () => {
        subFieldBeingEdited ? setNewSubfieldName(newSubfieldNameValue) : setNewFieldName(newNameValue);
        setNewFieldName(newNameValue);
        setIsEditingName(false);
        subFieldBeingEdited ? setIsSubfieldNameEdited(true) : setIsFieldNameEdited(true);
        setIsEdited(true);
    }

    const handleCancel = () => {
        setIsEditingName(false);
    }

    const onKeyUp = (e) => {
        if (e.key === 'Enter') {
            handleConfirm();
        } else if (e.key === 'Escape') {
            handleCancel();
        }
    }

    return (
        <div className="modal-container modal-container__rename">
            <h3>{subFieldBeingEdited ? t('renameSubfield') : t('renameFieldGroup')}</h3>
            <input type="text"
                className='edit-name-input'
                value={subFieldBeingEdited ? newSubfieldNameValue : newNameValue}
                onChange={subFieldBeingEdited ? (e) => setNewSubfieldNameValue(e.target.value) : (e) => setNewNameValue(e.target.value)}
                onKeyUp={onKeyUp}
            />
            <div className='button-container'>
                <button className="cancel-btn btn cancel-btn-modal" onClick={handleCancel}>{t('cancel')}</button>
                <button className="confirm-btn-modal btn" onClick={handleConfirm}>{t('confirm', { ns: 'editFieldMenu' })}</button>
            </div>
        </div>
    )
}
