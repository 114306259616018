import { api } from "../../api"
import { inboxLimit } from '../../config';

// Fetch the list of inbox ids the user has access to
export const fetchInboxIds = async (limit, offset) => {
    const response = await api.get('/inboxes', { params: { order_by: "name", limit, offset } });
    return response.data;
}

export const fetchInboxInfo = async (inboxId) => {
    const response = await api.get(`/inboxes/${inboxId}`);
    return response.data;
}

// Fetch the info object for each inbox the user has access to.
export const fetchAllInboxInfo = async (limit = inboxLimit, offset = 0) => {
    const allInboxes = await fetchInboxIds(limit, offset);
    const idResponse = allInboxes.results;
    const inboxData = await Promise.all(idResponse.map(async (id) => {
        try {
            const infoResponse = await fetchInboxInfo(id.id);
            return infoResponse;
        } catch (error) {
            return error;
        }
    }));
    return inboxData;
}

export const postNewInbox = async (newInbox) => {
    const response = await api.post(`/inboxes`, newInbox);
    return response.data;
}

export const patchInboxInfo = async (inboxId, newInbox) => {
    const response = await api.patch(`/inboxes/${inboxId}`, newInbox);
    return response.data;
}

export const createEmailAddress = async (inboxId) => {
    const response = await api.post(`/inboxes/${inboxId}/emails`)
    return response.data
}

export const fetchEmailAddresses = async (inboxId) => {
    const response = await api.get(`/inboxes/${inboxId}/emails`)
    return response.data
}
