import axios from 'axios';
import { baseApi } from './config';
import axiosRetry from 'axios-retry';

export const api = axios.create({
    baseURL: baseApi,
    withCredentials: true,
    headers: {
        'accept': 'application/json'
    },
});

axiosRetry(api, {
    retries: 3,
    retryDelay: axiosRetry.linearDelay(1000),
    retryCondition: axiosRetry.isNetworkOrIdempotentRequestError,
});
