import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import '../styles/CreateAPIKey.scss'
import CopyableTextBox from './CopyableTextBox';

export default function ShowAPIKey({ newAPIKey, setNewAPIKey }) {
    const { t } = useTranslation("userManagement");
    const [key, setKey] = useState({})

    newAPIKey.then(p => setKey(p.payload))

    return (
        <div className='create-api-key-container'>
            {t("showingAPIKey")}
            <CopyableTextBox text={key.secret_key} />
            <div className='create-api-key-btn-container'>
                <button
                    onClick={() => setNewAPIKey(null)}
                    className='btn create-btn create-api-key-btn'>
                    {t('done', { ns: "translation" })}
                </button>
            </div>
        </div>
    )
}
