import '../styles/OrElement.scss'

export default function OrElement() {
    return (
        <div className="parent-container">
            <div className="or-container">
                <div className="or-container::before"></div>
                <div className="or-text">or</div>
                <div className="or-container::after"></div>
            </div>
        </div>
    )
}
