import i18next from "i18next";

export const baseApi = import.meta.env.VITE_SMARTEXTRACT_API_URL || '/api';

export const getStatusOptions = () => [
    '—',
    i18next.t('statuses.inProgress'),
    i18next.t('statuses.complete'),
    i18next.t('statuses.checkLater'),
    i18next.t('statuses.junk')
];
export const svgWidthSize = 730;
export const svgHeightSize = 1031;
export const imgWidthResize = 600;
export const imgHeightResize = 810;
export const minImgWidth = 300;
export const minImgHeight = 200;
export const maxImgWidth = 1000;
export const maxImgHeight = 1000;

export const extractionSchema = "https://smartextract.ai/schemas/extraction/v0";

export const inboxLimit = 20;
export const documentLimit = 20;

export const highlightMinLength = 4;

/**
 * How long new API keys are valid by default, in milliseconds.
 */
export const defaultAPIKeyValidity = 30 * 24 * 60 * 60 * 1000
