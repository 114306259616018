import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { deleteAPIKey, fetchUserAPIKeys, fetchUserCredits, postNewAPIKey } from "./userService";

const initialState = {
    userStatus: 'idle',
    userMessage: '',
    user: {},
    userCredits: null,
    APIKeys: {},
    keyStatus: 'idle',
}


export const getUserCredits = createAsyncThunk('user/getUserCredits', async (_, thunkAPI) => {
    try {
        const response = await fetchUserCredits()
        return response;
    } catch (error) {
        if (error.response.data.detail) {
            const message = error.response.data.detail
            return thunkAPI.rejectWithValue(message);
        } else {
            const message = error.message
            return thunkAPI.rejectWithValue(message);
        }
    }
})

export const getUserAPIKeys = createAsyncThunk('user/getUserAPIKeys', async (limit, thunkAPI) => {
    try {
        const response = await fetchUserAPIKeys(limit)
        return response
    } catch (error) {
        if (error.response.data.detail) {
            const message = error.response.data.detail
            return thunkAPI.rejectWithValue(message);
        } else {
            const message = error.message
            return thunkAPI.rejectWithValue(message);
        }
    }
})

export const addNewAPIKey = createAsyncThunk('user/addNewAPIKey', async (APIKeyObject, thunkAPI) => {
    try {
        const response = await postNewAPIKey(APIKeyObject)
        return response
    } catch (error) {
        if (error.response.data.detail) {
            const message = error.response.data.detail
            return thunkAPI.rejectWithValue(message);
        } else {
            const message = error.message
            return thunkAPI.rejectWithValue(message);
        }
    }
})

export const deleteKey = createAsyncThunk('user/deleteKey', async (prefix, thunkAPI) => {
    try {
        const response = await deleteAPIKey(prefix)
        return response
    } catch (error) {
        if (error.response.data.detail) {
            const message = error.response.data.detail
            return thunkAPI.rejectWithValue(message);
        } else {
            const message = error.message
            return thunkAPI.rejectWithValue(message);
        }
    }
})
export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        reset: (state) => {
            state.userStatus = 'idle';
            state.message = '';
            state.user = {};
            state.userCredits = null;
        },
        resetKeyStatus: (state) => {
            state.keyStatus = 'idle';
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserCredits.pending, (state) => {
                state.userStatus = "loading";
            })
            .addCase(getUserCredits.fulfilled, (state, action) => {
                state.userStatus = 'success';
                state.userCredits = action.payload.balance;
            })
            .addCase(getUserCredits.rejected, (state, action) => {
                state.userStatus = 'failed';
                state.message = action.payload
            })

            .addCase(getUserAPIKeys.pending, (state) => {
                state.userStatus = "loading";
            })
            .addCase(getUserAPIKeys.fulfilled, (state, action) => {
                state.userStatus = 'success';
                state.APIKeys = action.payload
            })
            .addCase(getUserAPIKeys.rejected, (state, action) => {
                state.userStatus = 'error';
                state.message = action.payload
            })

            .addCase(addNewAPIKey.pending, (state) => {
                state.keyStatus = "adding";
            })
            .addCase(addNewAPIKey.fulfilled, (state) => {
                state.keyStatus = 'added';
            })
            .addCase(addNewAPIKey.rejected, (state, action) => {
                state.keyStatus = 'addingError';
                state.message = action.payload
            })

            .addCase(deleteKey.pending, (state) => {
                state.keyStatus = "deleting";
            })
            .addCase(deleteKey.fulfilled, (state) => {
                state.keyStatus = 'deleted';
            })
            .addCase(deleteKey.rejected, (state, action) => {
                state.keyStatus = 'deleteError';
                state.message = action.payload
            })
    }
})

export const { reset, resetKeyStatus } = userSlice.actions
export default userSlice.reducer
