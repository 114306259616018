import { api } from "../../api"
import { documentLimit } from '../../config';

export const processFileRequest = async (newFile) => {
    const url = `/pipelines/invoices`;
    const formData = new FormData();
    formData.append('document', newFile.file, newFile.name);

    const response = await api.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    });
    return response.data;
}


export const convertToImg = async (newFile) => {
    const url = `/pipelines/pdf-preview`;
    const formData = new FormData();
    formData.append('document', newFile.file, newFile.name);

    const response = await api.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Accept': 'image/jpeg'
        },
        responseType: 'blob',
    });
    const imageUrl = URL.createObjectURL(response.data);
    return imageUrl;
}

export const fetchAllDocumentsInfo = async (inboxID, offset, orderBy = 'date:desc', limit = documentLimit) => {
    const response = await api.get(`/inboxes/${inboxID}/documents`, { params: { order_by: orderBy, limit, offset } });
    return response.data;
}

export const fetchDocumentExtraction = async (documentID, recompute = false) => {
    const response = await api.get(`/documents/${documentID}/extraction?recompute=${recompute}`);
    return response.data;
}

export const fetchDocumentInfo = async (documentID) => {
    const response = await api.get(`/documents/${documentID}`);
    return response.data;
}

export const fetchDocumentStatus = async (documentID) => {
    const response = await api.get(`/documents/${documentID}/tags?key=status&history=false`);
    return response.data;
}


export const patchDocumentTags = async (documentID, status) => {
    const response = await api.patch(`/documents/${documentID}/tags`, {
        status
    });
    return response.data;
}
export const fetchFilePreview = async (documentID, page = 0) => {
    const response = await api.get(`/documents/${documentID}/preview?page=${page}`,
        {
            headers: {
                'Accept': 'image/*'
            },
            responseType: 'blob'
        }
    );
    const responseHeaders = response.headers;

    const imageUrl = URL.createObjectURL(response.data);
    return {
        imageUrl,
        headers: responseHeaders
    };
}


export const postDocumentExtraction = async (documentID, newData) => {
    const response = await api.post(`/documents/${documentID}/extraction`, newData,);
    return response.data;
}

export const addDocumentToInbox = async (inboxID, newFile) => {
    const url = `/inboxes/${inboxID}`;
    const formData = new FormData();
    formData.append('document', newFile);
    const response = await api.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        withCredentials: true,
    });
    return response.data;
}


export const fetchOCRExtraction = async (documentID, page = 0) => {
    const response = await api.get(`/documents/${documentID}/ocr?page=${page}`);
    return response.data;
}

export const deleteDocument = async (documentID) => {
    const response = await api.delete(`/documents/${documentID}`);
    return response.data;
}
