import '../../styles/Modals.scss';
import DragNDrop from '../DragNDrop';
import CloseButton from '../buttons/CloseButton';
import { useTranslation } from 'react-i18next';


export default function UploadModal({ setShowUploadModal }) {

    const { t } = useTranslation();


    return (
        <div className="modal-container modal-container__upload">
            <h3 className="upload-title">{t('uploadDocument')}</h3>
            <DragNDrop style={{ marginTop: '0' }} setShowUploadModal={setShowUploadModal} />
            <CloseButton setShowUploadModal={setShowUploadModal} />
        </div>
    )
}
