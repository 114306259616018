import React from 'react'
import { GoChevronRight } from "react-icons/go";
import '../../styles/Button.scss';
import { useSelector } from 'react-redux';
import { documentLimit } from '../../config';
import { useTranslation } from 'react-i18next';


export default function NextButton({ handleShowNext }) {
    const { documentsCount, offset } = useSelector(state => state.documents)
    const { t } = useTranslation();


    return (
        <button className='btn pagination-btn next-btn' onClick={handleShowNext} disabled={offset + documentLimit >= documentsCount} >
            {t('next')}
            <GoChevronRight className='navigation-icon' />
        </button>
    )
}
