import poExtractedData from './demo-poEN/poExtractedData.json'
import poDocumentOCR from './demo-poEN/poDocumentOCR.json'
import poPreview from './demo-poEN/po-en.jpeg'
import poExtractedDataDE from './demo-poDE/poExtractedDataDE.json'
import poDocumentOCRDE from './demo-poDE/poDocumentOCRDE.json'
import bestellungPreview from './demo-poDE/bestellung-preview.jpeg'
import energieausweis from './demo-energieausweis/energieausweis.json'
import energieausweisPreview from './demo-energieausweis/energieausweis-preview.jpeg';
import energieausweisOCR from './demo-energieausweis/energieausweisOCR.json';
import finanzDatenExtraction from './demo-finanzdaten/finanzdatenExtraction.json';
import finanzDatenOCR from './demo-finanzdaten/finanzdatenOCR.json';
import finanzDatenPreview from './demo-finanzdaten/finanzDaten.jpeg';



// const newData = Object.entries(energieausweis).map(([key, value]) => ({
//     label: key,
//     value
// }));

// const newEnergieausweisData = JSON.stringify(newData, null, 4);



// console.log(newEnergieausweisData);

export const demoData = {
    purchaseOrder: {
        id: 1,
        docType: "purchase order",
        dataExtraction: poExtractedData,
        documentOCR: poDocumentOCR,
        documentPreview: poPreview,
        thumbnails: [poPreview],
        language: 'en'
    },
    bestellung: {
        id: 2,
        docType: "bestellung",
        dataExtraction: poExtractedDataDE,
        documentOCR: poDocumentOCRDE,
        documentPreview: bestellungPreview,
        thumbnails: [bestellungPreview],
        language: 'de'
    },
    energieausweis: {
        id: 3,
        docType: "energieausweis",
        dataExtraction: energieausweis,
        documentPreview: energieausweisPreview,
        documentOCR: energieausweisOCR,
        thumbnails: [energieausweisPreview],
        language: 'de'
    },
    finanzdaten: {
        id: 4,
        docType: "finanzdaten",
        dataExtraction: finanzDatenExtraction,
        documentPreview: finanzDatenPreview,
        documentOCR: finanzDatenOCR,
        thumbnails: [finanzDatenPreview],
        language: 'de'
    }
}
