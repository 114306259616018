import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import '../../styles/RightSideMenu.scss'
import ValidationInput from './ValidationInput';
import ArrowDown from './ArrowDown';
import { useTranslation } from 'react-i18next';
import { LuClipboardCopy } from "react-icons/lu";
import { toast } from 'react-toastify';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Collapsible from 'react-collapsible';
import { demoData } from '../demoData.js'
import Cookies from 'js-cookie';



export default function RightSideMenu({
    updateBorderStyles,
    setBorderHighlightStyles,
    svgHeight,
    svgWidth,
    setIsHovered,
    validatedData,
    setValidatedData,
    page
}) {

    const { documentId } = useParams();
    const [showDataValidation, setShowDataValidation] = useState(false);
    const [editingIndex, setEditingIndex] = useState(null);
    const [closeInputField, setCloseInputField] = useState(false);
    const menuRef = useRef(null);
    const [newStatus, setNewStatus] = useState('')
    const { t } = useTranslation()
    const [showJson, setShowJson] = useState(false)
    const [showOptions, setShowOptions] = useState(false);
    const [editingSubIndex, setEditingSubIndex] = useState(null);
    const [fullDocumentExtraction, setFullDocumentExtraction] = useState([]);
    const { demoDocType } = useParams()

    const language = demoData[demoDocType]?.language || 'en';

    Cookies.set('language', language, { expires: 365 });

    useEffect(() => {
        if (demoDocType) {
            setFullDocumentExtraction(demoData[demoDocType].dataExtraction)
        }
    }, [demoDocType])



    const [inputValues, setInputValues] = useState(() => {
        const initialValues = {};
        validatedData.forEach((item, idx) => {
            initialValues[idx] = {};
            if (item.value && Array.isArray(item.value)) {
                item.value.forEach((subItem, subIdx) => {
                    initialValues[idx][subIdx] = subItem.value;
                });
            } else {
                initialValues[idx] = item.value;
            }
        });
        return initialValues;
    });


    const handleItemClick = (idx, text) => {
        setShowDataValidation(true);
        setEditingIndex(idx);
        setInputValues(prevValues => {
            const newValues = [...prevValues];
            newValues[idx] = text;
            return newValues;
        });
    }


    const handleSubItemClick = (idx, subIdx, text) => {
        setShowDataValidation(true);
        setEditingIndex(idx);
        setEditingSubIndex(subIdx);
        setInputValues(prevValues => {
            const newValues = [...prevValues];
            const newValue = [...newValues[idx]];
            newValue[subIdx] = text;
            newValues[idx] = newValue;
            return newValues;
        });
    };


    useEffect(() => {
        if (Array.isArray(validatedData)) {
            setInputValues(validatedData.map(() => ''));
        }
        setShowDataValidation(false);
    }, [validatedData]);

    useEffect(() => {
        if (closeInputField) {
            setShowDataValidation(false);
            setCloseInputField(false);
        }
    }, [closeInputField]);

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            toast.success(t('copiedToClipboard', { ns: 'userManagement' }))
        } catch (err) {
            toast.error(t('copyToClipboardError', { ns: 'errors' }))
        }
    }


    function calculateBorderBoxes(item) {
        let boxes = item.box ? [item.box] : [];

        if (!item.box) {
            const allMatchingBoxes = (Array.isArray(item.value) ? item.value : [item.value]).map(value => {
                return typeof value === 'string' && page.findMatchingBoxes(value) || [];
            });
            boxes = allMatchingBoxes.flat();
        }
        const highlightStyles = boxes.map(([left, top, right, bottom]) => {
            return {
                y: `${top * svgHeight}px`,
                x: `${left * svgWidth}px`,
                height: `${(bottom - top) * svgHeight}px`,
                width: `${(right - left) * svgWidth}px`,
            };
        });
        return highlightStyles;
    }

    const handleEditClick = () => {
        toast.warning(t('demoMessage', { ns: 'translation' }))
    }



    return (
        <>
            <div className="right-menu-main-container">

                <div className="extraction-json-container">
                    <div className='tab-container'>
                        <div className={!showJson ? 'extraction-json-tab active-tab' : 'extraction-json-tab inactive-tab'} onClick={() => setShowJson(false)}>{t('Extraction')}</div>
                        <div className={showJson ? 'underline' : 'active-underline'}></div>
                    </div>
                    <div className='tab-container'>
                        <div className={showJson ? 'extraction-json-tab active-tab' : 'extraction-json-tab inactive-tab'} onClick={() => setShowJson(true)}>JSON</div>
                        <div className={!showJson ? 'underline' : 'active-underline'}></div>
                    </div>
                </div>

                <div className='top-padding'></div>
                <div className="right-menu-container" ref={menuRef}>
                    <div className="right-menu-header">
                        {<button className='edit-fields-btn btn' onClick={() => handleEditClick()}>{t('customizeModel', { ns: 'editFieldMenu' })}</button>}
                    </div>

                    {showJson && (
                        <div className="json-output-container">
                            <LuClipboardCopy className='copy-paste' onClick={() => copyToClipboard(JSON.stringify(validatedData, null, 2))} title='Copy to clipboard' />
                            <pre className='json-output'>{JSON.stringify(fullDocumentExtraction, null, 2)}</pre>
                        </div>
                    )}
                    {!showJson && validatedData.map((item, idx) => {
                        const formattedLabel = item.label.replace(/_/g, ' ');
                        const finalLabel = formattedLabel.charAt(0).toUpperCase() + formattedLabel.slice(1);
                        const borderCoordinates = calculateBorderBoxes(item);
                        return (
                            <div
                                className="right-menu-item"
                                key={idx}
                                onMouseOver={() => {
                                    if (!Array.isArray(item.value)) {
                                        updateBorderStyles(borderCoordinates)
                                    }
                                }}
                                onMouseOut={() => setBorderHighlightStyles([])}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}>
                                <Collapsible trigger={[finalLabel, <MdOutlineKeyboardArrowRight key={finalLabel} />]} triggerTagName='h3' open={true} transitionTime={200}>
                                    {showDataValidation && editingIndex === idx && item && (item.value || item.value === "") && !Array.isArray(item.value) ?
                                        <ValidationInput
                                            closeInputField={closeInputField}
                                            setCloseInputField={setCloseInputField}
                                            setShowDataValidation={setShowDataValidation}
                                            inputValue={inputValues[idx]}
                                            setInputValue={(value) => {
                                                setInputValues(prevValues => {
                                                    const newValues = [...prevValues];
                                                    newValues[idx] = value;
                                                    return newValues;
                                                });
                                            }}
                                            idx={idx}
                                            validatedData={validatedData}
                                            setValidatedData={setValidatedData}
                                        />
                                        : (
                                            item.value && Array.isArray(item.value) ? (
                                                item.value.map((subItem, subIdx) => {
                                                    const borderCoordinates = calculateBorderBoxes(subItem);

                                                    return (
                                                        <div
                                                            key={subIdx}
                                                            className='right-menu-sub-item'
                                                            onMouseOver={() => {
                                                                updateBorderStyles(borderCoordinates)
                                                            }}
                                                            onMouseOut={() => setBorderHighlightStyles([])}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleSubItemClick(idx, subIdx, subItem.value);
                                                            }}>
                                                            <h4 className="label sub-label">{subItem.label}</h4>
                                                            {showDataValidation && editingIndex === idx && editingSubIndex === subIdx ? (
                                                                <ValidationInput
                                                                    closeInputField={closeInputField}
                                                                    setCloseInputField={setCloseInputField}
                                                                    setShowDataValidation={setShowDataValidation}
                                                                    subIdx={subIdx}
                                                                    idx={idx}
                                                                    inputValue={
                                                                        inputValues[idx] &&
                                                                            inputValues[idx][subIdx] ?
                                                                            inputValues[idx][subIdx] :
                                                                            ""}
                                                                    setInputValue={(value) => {
                                                                        setInputValues(prevValues => {
                                                                            const newValues = [...prevValues];
                                                                            if (!newValues[idx]) {
                                                                                newValues[idx] = {};
                                                                            }
                                                                            newValues[idx][subIdx] = value;
                                                                            return newValues;
                                                                        });
                                                                    }}

                                                                    validatedData={validatedData}
                                                                    setValidatedData={setValidatedData}
                                                                    inputValues={inputValues}
                                                                />
                                                            ) : (
                                                                <p className={subItem.value ? 'text' : ' text label-in-text'}>
                                                                    {subItem.value ? subItem.value : (
                                                                        language === 'en' ?
                                                                            'Enter ' + finalLabel.toLowerCase() + "'s " + subItem.label.toLowerCase() :
                                                                            finalLabel + " " + subItem.label + " eingeben"
                                                                    )}
                                                                </p>
                                                            )}
                                                        </div>
                                                    )
                                                }
                                                )
                                            ) : (
                                                <p className={item.value ? 'text' : ' text label-in-text'} onClick={() => {
                                                    if (!Array.isArray(item.value)) {
                                                        handleItemClick(idx, item.value);
                                                    }
                                                }}>
                                                    {item.value ? item.value : (
                                                        language === 'en' ?
                                                            'Enter' + ' ' + finalLabel.toLowerCase() :
                                                            finalLabel + ' eingeben')}
                                                </p>
                                            )
                                        )}
                                </Collapsible>
                            </div>
                        );
                    })}
                    {fullDocumentExtraction &&
                        <div className="status-container">
                            <div className="status-box">
                                <h3>Set status</h3>
                                <ArrowDown selectedOption={newStatus} documentId={documentId} menuRef={menuRef} setNewStatus={setNewStatus} showOptions={showOptions} setShowOptions={setShowOptions} />
                            </div>
                            <div className="status">
                                <p className='status-text'> {newStatus}</p>
                            </div>
                        </div>
                    }
                    {!showOptions && (
                        <>
                            <div className='separator'></div>
                            <div className="custom-template-msg">
                                <p className='custom-template-msg-text'  >{t('tailoredSolution')} </p>
                                <p className='custom-template-msg-text'>
                                    <a className='custom-template-msg-link' href={`mailto:${t('emailAddress')}`}>{t('contactUs')}</a>
                                    {t('personalizedTemplates')}
                                </p>
                            </div>
                            <div className='separator'></div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}


