import { useTranslation } from 'react-i18next';
import '../../styles/Button.scss';

export default function SendLinkButton() {
    const { t } = useTranslation(['translation', 'userManagement']);

    return (
        <button type='submit' className='send-link-btn btn'>{t('sendLink', { ns: 'userManagement' })}</button>
    )
}
