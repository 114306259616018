import React from 'react'
import { GoChevronLeft } from "react-icons/go";
import { useSelector } from 'react-redux';
import '../../styles/Button.scss';
import { useTranslation } from 'react-i18next';



export default function PreviousButton({ handleShowLess }) {
    const { offset } = useSelector(state => state.documents);
    const { t } = useTranslation();

    return (
        <button className='btn pagination-btn previous-btn' onClick={handleShowLess} disabled={offset === 0}>
            <GoChevronLeft className='navigation-icon' />
            {t('previous')}
        </button>
    )
}
