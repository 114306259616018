import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { updateDocumentExtraction } from "../features/files/fileSlice";
import SaveButton from "../components/buttons/SaveButton";
import CancelButton from "../components/buttons/CancelButton";
import '../styles/ValidationInput.scss'
import { extractionSchema } from "../config";


export default function ValidationInput({
    setInputValue,
    inputValue,
    idx,
    setCloseInputField,
    validatedData,
    setValidatedData,
    subIdx,
    inputValues }) {



    const { documentId } = useParams();
    const dispatch = useDispatch();

    const handleInputChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
    }

    const handleSave = ({ idx, subIdx }) => {
        const newValidatedData = [...validatedData];
        if (subIdx !== undefined) {
            newValidatedData[idx] = {
                ...newValidatedData[idx],
                value: newValidatedData[idx].value.map((item, i) => i === subIdx ? { ...item, value: inputValues[idx][subIdx] } : item)
            }
        } else {
            newValidatedData[idx] = {
                ...newValidatedData[idx],
                value: inputValue
            };
        }

        setValidatedData([...newValidatedData]);
        const updatedData = {
            schema: extractionSchema,
            entities: newValidatedData
        }
        dispatch(updateDocumentExtraction({ docId: documentId, newData: updatedData }))
        setCloseInputField(true);
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSave({ idx, subIdx });
        } else if (e.key === 'Escape') {
            setCloseInputField(true);
        }
    }

    const handleCancel = () => {
        setCloseInputField(true);
    }

    return (
        <div className="validation-input-container">
            <textarea
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                tabIndex={0}
                className="data-validation-input"
                rows="5"
            />
            <div className="validation-buttons">
                <CancelButton handleCancel={handleCancel} />
                <SaveButton
                    handleSave={handleSave}
                    idx={idx}
                    subIdx={subIdx}
                />
            </div>
        </div>
    )
}
