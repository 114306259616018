import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../styles/EditFieldsMenu.scss'
import { useTranslation } from 'react-i18next';
import { IoMdCloseCircleOutline } from "react-icons/io";
import TemplateFields from './TemplateFields';
import { getInboxDetails } from '../features/inboxes/inboxSlice';
import { editPipelineTemplate, getPipelineInfo } from '../features/pipelines/pipelinesSlice';
import AddNewFieldButton from './buttons/AddNewFieldButton';
import SaveButton from './buttons/SaveButton';
import CancelButton from './buttons/CancelButton';
import WorkingLoader from './WorkingLoader';
import AddNewFolder from './AddNewFolder';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';




export default function EditFieldsMenu({ setIsEditMenuOpen }) {
    const { pipelineId, inboxStatus } = useSelector(state => state.inbox);
    const { t } = useTranslation(['editFieldMenu', 'translation']);
    const [isAddNewField, setIsAddNewField] = useState(false);
    const containerRef = useRef(null);
    const dispatch = useDispatch();
    const { pipelineTemplateFields, pipelinesStatus } = useSelector(state => state.pipelines);
    const [customTemplateFields, setCustomTemplateFields] = useState([]);
    const isSubField = false;
    const [isEdited, setIsEdited] = useState(false);
    const { isLoadingDocumentExtraction } = useSelector(state => state.documents)
    const [isLoading, setIsLoading] = useState(false);
    const [isAddNewFolder, setIsAddNewFolder] = useState(false);
    const [openedFolderId, setOpenedFolderId] = useState(null);
    const inboxId = useSelector(state => state.documents.documentInfo.inbox_id);
    const scrollTargetRef = useRef(null);

    useEffect(() => {
        if (inboxId) {
            dispatch(getInboxDetails(inboxId))
        }
    }, [inboxId]) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        if (pipelineId) {
            dispatch(getPipelineInfo(pipelineId))
        }
    }, [pipelineId]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (pipelineTemplateFields) {
            setCustomTemplateFields(pipelineTemplateFields.map(item => ({
                ...item,
                id: uuidv4(), // add a unique id to the item
            })));
        }
    }, [pipelineTemplateFields])


    const handleSave = () => {
        const newTemplate = {
            "template": {
                "version": 0,
                // eslint-disable-next-line no-unused-vars
                "fields": customTemplateFields.map(({ id, ...item }) => item)
            }
        }
        setIsAddNewField(false);
        setIsAddNewFolder(false);
        dispatch(editPipelineTemplate({ pipelineId: pipelineId, newPipelineTemplate: newTemplate })).then(() => {
            setIsEdited(false);
            setIsEditMenuOpen(false);
        });

    }

    const handleAddNewFolder = () => {
        setIsAddNewFolder(true);
        setTimeout(() => {
            scrollTargetRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
        }, 100);
    }

    const handleAddAndConfirmNewFolder = (newField) => {
        const newFields = [...customTemplateFields, newField];
        setCustomTemplateFields(newFields);
        setIsAddNewFolder(false);
        setOpenedFolderId(newField.id)
    }


    useEffect(() => {
        if (pipelinesStatus === 'error') {
            toast.error(t('generalError', { ns: 'errors' }));
        }
    }, [pipelinesStatus])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoadingDocumentExtraction || pipelinesStatus === 'loading' || inboxStatus === 'loading') {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [isLoadingDocumentExtraction, pipelinesStatus, inboxStatus])

    const handleCancel = () => {
        setIsEditMenuOpen(false);
    }


    return (
        <>
            <div className='edit-menu-container' ref={containerRef}>
                {isLoading && <WorkingLoader />}
                {!isLoading && <>
                    <div className="edit-menu-header">
                        <p className='edit-text'>{t('editText', { ns: 'editFieldMenu' })} </p>
                        <div className='close-icon-wrapper' onClick={() => setIsEditMenuOpen(false)}>
                            {!isEdited && <IoMdCloseCircleOutline className='close-icon' />}
                        </div>
                    </div>
                    <div className='edit-button-container' >
                        {isEdited && <CancelButton handleCancel={handleCancel} />}
                        {isEdited && <SaveButton handleSave={handleSave} />}
                    </div>
                    <TemplateFields
                        customTemplateFields={customTemplateFields}
                        setCustomTemplateFields={setCustomTemplateFields}
                        setIsEdited={setIsEdited}
                        isEdited={isEdited}
                        setIsEditMenuOpen={setIsEditMenuOpen}
                        openedFolderId={openedFolderId}
                        isAddNewField={isAddNewField}
                        setIsAddNewField={setIsAddNewField}
                        setOpenedFolderId={setOpenedFolderId}
                        scrollTargetRef={scrollTargetRef}
                    />

                    <AddNewFolder
                        setIsAddNewFolder={setIsAddNewFolder}
                        isAddNewFolder={isAddNewFolder}
                        customTemplateFields={customTemplateFields}
                        isSubField={isSubField}
                        isEdited={isEdited}
                        setIsEdited={setIsEdited}
                        onConfirm={(newField) => handleAddAndConfirmNewFolder(newField)}
                        scrollTargetRef={scrollTargetRef}
                    />
                    {!isAddNewFolder && <AddNewFieldButton isSubField={isSubField} handleAddNewField={handleAddNewFolder} />}
                </>}
            </div>
        </>
    )
}
