import React, { useEffect, useRef, useState } from 'react'
import TemplateSubfields from './TemplateSubfields';
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import Collapsible from 'react-collapsible';
import AddNewField from './AddNewField';
import AddNewFieldButton from './buttons/AddNewFieldButton';
import '../styles/TemplateFields.scss';
import DeleteFieldButton from './buttons/DeleteFieldButton';
import ConfirmDeleteModal from './modals/ConfirmDeleteModal';
import EditNameModal from './modals/EditNameModal';

export default function TemplateFields({
    customTemplateFields,
    setCustomTemplateFields,
    setIsEdited,
    openedFolderId,
    isAddNewField,
    setIsAddNewField
}) {


    const isSubField = true;
    const [editedFieldId, setEditedFieldId] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [fieldToDelete, setFieldToDelete] = useState(null);
    const scrollToCardRef = useRef(null);
    const [isEditingName, setIsEditingName] = useState(false);
    const [fieldBeingEdited, setFieldBeingEdited] = useState(null);
    const [newFieldName, setNewFieldName] = useState('');
    const [isFieldNameEdited, setIsFieldNameEdited] = useState(false);
    const [subFieldBeingEdited, setSubFieldBeingEdited] = useState(null);
    const [isSubfieldNameEdited, setIsSubfieldNameEdited] = useState(false);
    const [newSubfieldName, setNewSubfieldName] = useState('');



    const handleDeleteSubField = (fieldId, subFieldIdx) => {
        setCustomTemplateFields(fields => fields.map((field) => {
            if (field.id !== fieldId) return field;

            return {
                ...field,
                fields: field.fields.filter((_, i) => i !== subFieldIdx)
            }
        }))
        setIsEdited(true);
        setEditedFieldId(fieldId);
        setIsAddNewField(false);
    }

    const handleAddSubfield = (fieldId, newSubfield) => {
        setCustomTemplateFields(fields => fields.map((field) => {
            if (field.id !== fieldId) return field;
            return {
                ...field,
                fields: [...field.fields, newSubfield]
            }
        }))
        setEditedFieldId(fieldId);
        setIsAddNewField(false);
        setIsEdited(true);
    }

    const handleAddNewField = (id) => {
        setIsAddNewField(true);
        setEditedFieldId(id);
        setTimeout(() => {
            scrollToCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        }, 100);
    }


    const handleDeleteField = (id) => {
        const newTemplate = customTemplateFields.filter((item) => item.id !== id);
        setCustomTemplateFields(newTemplate);
        setIsEdited(true);
        setIsAddNewField(false);
        setConfirmDelete(false);
    }

    useEffect(() => {
        if (openedFolderId != null) {
            handleAddNewField(openedFolderId);
        }
    }, [openedFolderId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCancelButtonInSubfield = (folder) => {
        setEditedFieldId(folder.id);
        if (folder.id === editedFieldId) {
            if (folder.fields?.length === 0) {
                const newTemplate = customTemplateFields.filter((item) => item.id !== folder.id);
                setCustomTemplateFields(newTemplate);
            } else {
                setIsAddNewField(false);
            }
        }
    }

    const handleRenameField = (field) => {
        setSubFieldBeingEdited(null);
        setFieldBeingEdited(field);
        setNewFieldName(field.name);
        setIsEditingName(true);
    };

    useEffect(() => {
        if (isFieldNameEdited) {
            setCustomTemplateFields(fields => fields.map(field => {
                if (field.id === fieldBeingEdited.id) {
                    return { ...field, name: newFieldName };
                }
                return field;
            }));
            setIsFieldNameEdited(false);
        }
        if (isSubfieldNameEdited) {
            setCustomTemplateFields(fields => fields.map(field => {
                if (field.id === fieldBeingEdited.id) {
                    return {
                        ...field,
                        fields: field.fields.map((subField) => {
                            if (subField.name === subFieldBeingEdited.name) {
                                return { ...subField, name: newSubfieldName };
                            }
                            return subField;
                        })
                    };
                }
                return field;
            }));
            setIsSubfieldNameEdited(false);
        }
    }, [newFieldName, customTemplateFields, fieldBeingEdited, isFieldNameEdited, isSubfieldNameEdited, subFieldBeingEdited, newSubfieldName]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleRenameSubField = (field, subField) => {
        setFieldBeingEdited(field);
        setSubFieldBeingEdited(subField);
        setNewSubfieldName(subField.name);
        setIsEditingName(true);
    };

    return (
        <>
            {customTemplateFields && customTemplateFields.length > 0 && customTemplateFields.map((item, idx) => {
                return (
                    <div key={idx}>
                        {confirmDelete &&
                            <ConfirmDeleteModal
                                handleDelete={() => handleDeleteField(fieldToDelete)}
                                setConfirmDelete={setConfirmDelete}
                            />
                        }
                        {isEditingName &&
                            <EditNameModal
                                setIsEditingName={setIsEditingName}
                                newFieldName={newFieldName}
                                setNewFieldName={setNewFieldName}
                                setIsFieldNameEdited={setIsFieldNameEdited}
                                setIsEdited={setIsEdited}
                                subFieldBeingEdited={subFieldBeingEdited}
                                setNewSubfieldName={setNewSubfieldName}
                                setIsSubfieldNameEdited={setIsSubfieldNameEdited}
                            />
                        }
                        <div className="edit-menu-card" >
                            <Collapsible
                                trigger={[item.name, <MdOutlineKeyboardArrowRight key={item.name} />]}
                                triggerTagName='h3'
                                open={item.id === openedFolderId}
                                transitionTime={200}>
                                <button className="rename-btn btn" onClick={() => handleRenameField(item)}>Rename</button>
                                <TemplateSubfields
                                    item={item}
                                    handleDeleteSubField={(subIdx) => handleDeleteSubField(item.id, subIdx)}
                                    handleRenameSubField={(subItem) => handleRenameSubField(item, subItem)}
                                />
                                {editedFieldId === item.id &&
                                    <AddNewField
                                        setIsAddNewField={setIsAddNewField}
                                        isAddNewField={isAddNewField}
                                        onConfirm={(newSubField) => handleAddSubfield(item.id, newSubField)}
                                        customTemplateFields={customTemplateFields}
                                        isSubField={isSubField}
                                        setIsEdited={setIsEdited}
                                        scrollToCardRef={scrollToCardRef}
                                        onCancel={() => handleCancelButtonInSubfield(item)}
                                    />
                                }
                                {(editedFieldId !== item.id || !isAddNewField) &&
                                    <div className='button-container'>
                                        <DeleteFieldButton handleDeleteField={() => { setFieldToDelete(item.id); setConfirmDelete(true) }} />
                                        <AddNewFieldButton isSubField={isSubField} handleAddNewField={() => handleAddNewField(item.id)} />
                                    </div>
                                }
                            </Collapsible>
                        </div>
                    </div>
                )
            })}
        </>
    )
}
