import { useTranslation } from 'react-i18next';
import '../../styles/Button.scss';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { resetEmailState, resetVerificationState, sendVerificationEmail } from '../../features/auth/authSlice';
import { useEffect } from 'react';

export default function SendNewLinkButton() {
    const { t } = useTranslation(['translation', 'userManagement']);
    const email = useSelector(state => state.auth.user);
    const navigate = useNavigate();
    const { isEmailSent } = useSelector(state => state.auth);
    const dispatch = useDispatch();



    const sendNewLink = () => {
        dispatch(resetVerificationState());
        if (!email) {
            toast.error(t('loginFirst', { ns: 'errors' }));
            navigate('/login');
        } else {
            dispatch(sendVerificationEmail(email));
        }
    }

    useEffect(() => {
        if (isEmailSent) {
            toast.success(t('emailSentSuccess', { ns: 'userManagement' }));
            dispatch(resetEmailState());
            navigate('/');
        }
    }, [isEmailSent, t, navigate, dispatch]);

    return (
        <button onClick={sendNewLink} className='send-new-link-btn btn'>{t('sendNewLink', { ns: 'userManagement' })}</button>
    )
}
