import React from 'react';
import { useTranslation } from 'react-i18next';
import { LuClipboardCopy } from "react-icons/lu";
import { toast } from 'react-toastify';

export default function CopyableTextBox({ text, title = null, onCopyMessage = null }) {
    const { t } = useTranslation();
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(text);
            toast.success(onCopyMessage || t("didCopyToClipboard"))
        } catch (err) {
            toast.error(t('copyToClipboardError', { ns: 'errors' }))
        }
    }

    return (
        <div className='inbox-id-container inbox-item-container'>
            {title && <p className='inbox-item-label'>{title}</p>}
            <div className='inbox-settings-data'>
                <p className='inbox-settings-data__text'>{text}</p>
                <div className='settings-copy-paste'>
                    <LuClipboardCopy
                        className='settings-copy-paste-icon'
                        onClick={copyToClipboard}
                        title='Copy to clipboard'
                    />
                </div>
            </div>
        </div>
    )
}
