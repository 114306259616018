import { useState } from 'react';
import Hero from '../components/Hero';
import mailSent from '../assets/illustrations/mailSent.svg';
import { useTranslation } from 'react-i18next';
import OrElement from '../components/OrElement';
import '../styles/EmailSent.scss';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import { baseApi } from '../config';
import axios from 'axios';

export default function EmailSent() {
    const { t } = useTranslation();
    const [showLoader, setShowLoader] = useState(false);
    const email = localStorage.getItem('email');

    const resendLink = async () => {
        try {
            await axios.post(`${baseApi}/auth/forgot-password`, { email },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
            toast.success(t('emailSentSuccess', { ns: 'userManagement' }));
        } catch (error) {
            if (error.response.data.detail) {
                setShowLoader(false);
                toast.error(t('generalError', { ns: 'errors' }));
            }
        }
    }

    return (
        <main className='main-container main-email-sent-container'>
            <Hero />
            {showLoader ? <Loader /> : (
                <div className='email-sent-container'>
                    <img src={mailSent} alt="email sent illustration" className='email-sent-illustration' />
                    <div className='email-sent-text-container'>
                        <h3 className='email-sent-title'>{t('emailSent', { ns: 'userManagement' })}</h3>
                        <p className='email-sent-text'>{t('checkInbox', { ns: 'userManagement' })}</p>
                        <p className='email-sent-text'>{t('findEmail', { ns: 'userManagement' })}</p>
                    </div>
                    <OrElement />
                    <button className='resend-email-link' onClick={resendLink}>{t('resendEmail', { ns: 'userManagement' })}</button>
                </div>
            )}
        </main>
    )
}
