import React, { useEffect, useState, useContext } from 'react'
import { TbFileInvoice, TbReceipt } from "react-icons/tb";
import { HiOutlineDocumentText } from "react-icons/hi2";
import '../styles/NewInboxPipeline.scss';
import InboxNameModal from './modals/InboxNameModal';
import { useDispatch, useSelector } from 'react-redux';
import { createEmail, getInboxInfo, getInboxesCount, resetNewInboxID } from '../features/inboxes/inboxSlice';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ModalContext } from '../contexts/ModalContext';
import { resetDocumentsData } from '../features/files/fileSlice';
import { createPipelineAndInbox, getAllTemplates, resetTemplates } from '../features/templates/templatesSlice';
import Cookies from 'js-cookie';

const templateIcons = {
    "invoice": <TbFileInvoice className='pipeline-icon' />,
    "receipt": <TbReceipt className='pipeline-icon' />,
    "default": <HiOutlineDocumentText className='pipeline-icon' />
}
export default function NewInboxPipeline() {
    // const [pipeline, setPipeline] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [inboxName, setInboxName] = useState('');
    const { newInboxID, isInboxAdded, inboxData } = useSelector((state) => state.inbox);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isFirstInbox, setIsFirstInbox] = useState(true);
    const { setModalOn } = useContext(ModalContext);
    const { templatesList } = useSelector((state) => state.templates);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const selectedLanguage = Cookies.get('language') || 'en';
    const { isErrorInPipeline } = useSelector((state) => state.templates);

    useEffect(() => {
        dispatch(getAllTemplates(selectedLanguage))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (inboxData && inboxData.length > 0) {
            setIsFirstInbox(false);
        }
    }, [inboxData])

    const handleClick = async (templateName, templateId) => {
        setSelectedTemplate({ name: templateName, id: templateId });
        if (isFirstInbox && templateId) {
            dispatch(createPipelineAndInbox({
                pipelineName: templateName,
                templateId: templateId,
                ocrId: null,
                chatId: null,
                lang: selectedLanguage,
                inboxName: templateName,
                dispatch: dispatch
            }));
        } else {
            setShowModal(true);
            setModalOn(true);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    useEffect(() => {
        if (newInboxID && isInboxAdded) {
            dispatch(createEmail(newInboxID))
            navigate(`/inboxes/${newInboxID}`)
            dispatch(getInboxInfo()).then(() => {
                toast.success(t('inboxCreationSuccess', { ns: 'userManagement' }), {
                    toastId: 'inboxCreationSuccess1',
                })
            })
            dispatch(getInboxesCount());
            dispatch(resetNewInboxID())
        } else if (isErrorInPipeline) {
            toast.error(t('inboxCreationError', { ns: 'userManagement' }), {
                toastId: 'inboxCreationError1',
            })
            dispatch(resetTemplates())
        }
    }, [newInboxID, navigate, isInboxAdded, dispatch, isErrorInPipeline])


    const handleSubmit = async () => {
        if (selectedTemplate) {
            const { name, id } = selectedTemplate;
            dispatch(createPipelineAndInbox({
                pipelineName: name,
                templateId: id,
                ocrId: null,
                chatId: null,
                lang: selectedLanguage,
                inboxName: inboxName,
                dispatch: dispatch
            }));
        }
        dispatch(resetDocumentsData())
        dispatch(resetTemplates())
        setModalOn(false);
        setShowModal(false);
    }

    return (
        <div className='pipelines-container'>
            {templatesList && templatesList.length > 0 && templatesList.map((template) => {
                return (
                    <div className={showModal ? ' pipeline-box pipeline-box__inactive' : 'pipeline-box'} key={template.id} onClick={() => handleClick(template.name, template.id)}>
                        {templateIcons[template.id] || templateIcons['default']}
                        <h4 className='pipeline-name'>{template.name}</h4>
                        <p className='pipeline-description'>{template.description}</p>
                    </div>
                )
            })}
            {showModal &&
                <InboxNameModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    setInboxName={setInboxName}
                    inboxName={inboxName}
                    handleSubmit={handleSubmit}
                />}
        </div>
    )
}
