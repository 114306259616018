import React, { useEffect, useState } from 'react';
import '../styles/DataValidation.scss'
import RightSideMenu from '../components/RightSideMenu';
import SVGContainer from '../components/SVGContainer';
import { svgWidthSize, svgHeightSize, extractionSchema } from '../config';
import { useDispatch, useSelector } from 'react-redux';
import { getOCRExtraction, resetDocumentExtraction } from '../features/files/fileSlice';
import { Resizable } from "re-resizable";
import EditFieldsMenu from '../components/EditFieldsMenu';
import ThumbnailsPreview from '../components/ThumbnailsPreview';
import { useParams } from 'react-router-dom';
import { Page } from '../pageUtils';



export default function DataValidation() {
    const [isHovered, setIsHovered] = useState(false);
    const [svgWidth, setSvgWidth] = useState(svgWidthSize);
    const [svgHeight, setSvgHeight] = useState(svgHeightSize);
    const [borderHighlightStyles, setBorderHighlightStyles] = useState([]);

    const updateBorderStyles = (highlightStyles) => {
        setBorderHighlightStyles(highlightStyles);
    }

    const { isErrorDocumentExtraction, messageDoc, documentOCR, fullDocumentExtraction } = useSelector(state => state.documents)
    const [showNoCreditError, setShowNoCreditError] = useState(false);
    const dispatch = useDispatch();
    const [isEditMenuOpen, setIsEditMenuOpen] = useState(false);
    const [validatedData, setValidatedData] = useState([]);
    const { documentId } = useParams();
    const [page, setPage] = useState(null);




    useEffect(() => {
        if (isErrorDocumentExtraction && messageDoc && messageDoc.includes('Not enough credits')) {
            dispatch(resetDocumentExtraction())
            setShowNoCreditError(true)
        }
    }, [isErrorDocumentExtraction, messageDoc, showNoCreditError, fullDocumentExtraction, dispatch])


    useEffect(() => {
        if (fullDocumentExtraction?.schema === extractionSchema) {
            setValidatedData(fullDocumentExtraction.entities)
        }
    }, [fullDocumentExtraction])

    useEffect(() => {
        dispatch(getOCRExtraction({ docId: documentId, page: 0 }))
    }, [dispatch, documentId, fullDocumentExtraction]);


    useEffect(() => {
        if (documentOCR && documentOCR.words) {
            setPage(new Page(documentOCR.words, documentOCR.boxes, documentOCR.width, documentOCR.height));
        }
    }, [documentOCR, fullDocumentExtraction, documentId]);



    return (
        <div className='data-validation-main-container'>
            <div className="data-validation-container">
                <ThumbnailsPreview showNoCreditError={showNoCreditError} />
                <SVGContainer
                    borderHighlightStyles={borderHighlightStyles}
                    isHovered={isHovered}
                    svgHeight={svgHeight}
                    svgWidth={svgWidth}
                    setSvgHeight={setSvgHeight}
                    setSvgWidth={setSvgWidth}
                    showNoCreditError={showNoCreditError}
                    validatedData={validatedData}
                    page={page}
                />
                <Resizable
                    defaultSize={{ width: 550 }}
                    handleClasses={{
                        top: "pointer-events-none",
                        bottom: "pointer-events-none",
                        right: "pointer-events-none",
                        topRight: "pointer-events-none",
                        bottomRight: "pointer-events-none",
                        bottomLeft: "pointer-events-none",
                        topLeft: "pointer-events-none",
                    }}
                    minWidth={300}
                    maxWidth={900}
                >{!isEditMenuOpen ?
                    <RightSideMenu
                        updateBorderStyles={updateBorderStyles}
                        setBorderHighlightStyles={setBorderHighlightStyles}
                        svgWidth={svgWidth}
                        svgHeight={svgHeight}
                        setIsHovered={setIsHovered}
                        showNoCreditError={showNoCreditError}
                        validatedData={validatedData}
                        setValidatedData={setValidatedData}
                        documentOCR={documentOCR}
                        isEditMenuOpen={isEditMenuOpen}
                        setIsEditMenuOpen={setIsEditMenuOpen}
                        page={page}
                    /> :
                    <EditFieldsMenu
                        setIsEditMenuOpen={setIsEditMenuOpen}
                    />}
                </Resizable>
            </div>
        </div>
    )
}
