
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import authService from './authService'


const userInfo = Cookies.get('userInfo');

const initialState = {
    user: userInfo ? userInfo : null,
    isLoggedIn: userInfo ? true : false,
    isSignedUp: false,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
    isEmailSent: false,
    isVerified: false,
}



export const loginUser = createAsyncThunk('auth/login', async (userData, thunkAPI) => {
    try {
        return await authService.login(userData);

    } catch (error) {
        if (error.response.data.detail) {
            const message = error.response.data.detail
            return thunkAPI.rejectWithValue(message);
        } else {
            const message = error.message
            return thunkAPI.rejectWithValue(message);
        }
    }
})

export const logoutUser = createAsyncThunk('auth/logout', async () => {
    try {
        await authService.logout();
    } catch (error) {
        return error.message;
    }
})


export const registerUser = createAsyncThunk('auth/register', async (userData, thunkAPI) => {
    try {
        return await authService.register(userData);
    } catch (error) {
        if (error.response.data.detail) {
            const message = error.response.data.detail
            return thunkAPI.rejectWithValue(message);
        } else {
            const message = error.message
            return thunkAPI.rejectWithValue(message);
        }
    }
});


export const forgotPassword = createAsyncThunk('auth/forgotPassword', async (userEmail, thunkAPI) => {
    try {
        const response = await authService.forgotPassword(userEmail);
        return response.statusText;
    } catch (error) {
        if (error.response.data.detail) {
            const message = error.response.data.detail
            return thunkAPI.rejectWithValue(message);
        } else {
            const message = error.message
            return thunkAPI.rejectWithValue(message);
        }
    }
})

export const sendVerificationEmail = createAsyncThunk('auth/sendVerificationEmail', async (userEmail, thunkAPI) => {
    try {
        const response = await authService.requestVerificationEmail(userEmail);
        return response.statusText;
    } catch (error) {
        if (error.response.data.detail) {
            const message = error.response.data.detail
            return thunkAPI.rejectWithValue(message);
        } else {
            const message = error.message
            return thunkAPI.rejectWithValue(message);
        }
    }
})

export const verifyAccount = createAsyncThunk('auth/verifyAccount', async (token, thunkAPI) => {
    try {
        const response = await authService.verifyEmail(token);
        return response.data;
    } catch (error) {
        if (error.response.data.detail) {
            const message = error.response.data.detail
            return thunkAPI.rejectWithValue(message);
        } else {
            const message = error.message
            return thunkAPI.rejectWithValue(message);
        }
    }
})


export const authSlice = createSlice({

    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isSignedUp = false;
            state.isLoggedIn = false;
            state.isError = false;
            state.isSuccess = false;
            state.message = '';
            state.user = null;
            state.isEmailSent = false;
            state.isVerified = false;
        },
        resetEmailState: (state) => {
            state.isEmailSent = false;
        },
        resetVerificationState: (state) => {
            state.isVerified = false;
            state.isError = false;
            state.message = "";
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isLoggedIn = true;
                state.isSignedUp = true;
                state.user = action.payload;

            })
            .addCase(registerUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isLoggedIn = false;
                state.isSignedUp = false;
                state.isError = true;
                state.message = action.payload;
                state.user = null;
            })
            .addCase(loginUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isLoggedIn = true;
                state.user = action.payload;

            })
            .addCase(loginUser.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.isLoggedIn = false;
                state.user = null;
            })
            .addCase(logoutUser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(logoutUser.fulfilled, (state) => {
                state.isLoading = false;
                state.isLoggedIn = false;
                state.isSuccess = true;
                state.user = null;
            })
            .addCase(logoutUser.rejected, (state) => {
                state.isLoading = false;
                state.isLoggedIn = false;
                state.isError = true;
                state.message = '';
                state.user = null;
            })
            .addCase(forgotPassword.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(forgotPassword.fulfilled, (state) => {
                state.isLoading = false;
                state.isEmailSent = true;
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(sendVerificationEmail.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(sendVerificationEmail.fulfilled, (state) => {
                state.isLoading = false;
                state.isEmailSent = true;
            })
            .addCase(sendVerificationEmail.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(verifyAccount.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(verifyAccount.fulfilled, (state) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isVerified = true;
            })
            .addCase(verifyAccount.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.isVerified = false;
            })
    }

})

export const { reset, resetVerificationState, resetEmailState } = authSlice.actions;
export default authSlice.reducer;
