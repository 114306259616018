import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { getDocumentPreview, getOCRExtraction, getThumbnailsPreview, hideBoundingBoxes, hideThumbnails, showThumbnails } from '../features/files/fileSlice';
import '../styles/ThumbnailsPreview.scss';




export default function ThumbnailsPreview({ showNoCreditError }) {
    const { pageCount, currentPreviewPage, thumbnailsVisible } = useSelector((state) => state.documents)
    const { documentId } = useParams();
    const [thumbnails, setThumbnails] = useState([])
    const dispatch = useDispatch()
    const [currentDocumentId, setCurrentDocumentId] = useState(null);
    const [currentPageCount, setCurrentPageCount] = useState(null);

    useEffect(() => {
        if (documentId !== currentDocumentId) {
            setCurrentDocumentId(documentId);
            setCurrentPageCount(pageCount);
        }
    }, [documentId, pageCount]); // eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() => {
        if (currentPageCount === null) return;
        let isCancelled = false;
        dispatch(hideThumbnails());
        const fetchThumbnailsPromises = [];

        if (pageCount === 0) return;

        for (let page = 0; page < pageCount; page++) {
            const fetchPromise = dispatch(getThumbnailsPreview({ docId: documentId, page }))
                .then(thumbnail => {
                    if (isCancelled) return null;
                    return { imageUrl: thumbnail.payload, page: page };
                });
            fetchThumbnailsPromises.push(fetchPromise);
        }
        Promise.all(fetchThumbnailsPromises).then(fetchedThumbnails => {
            if (isCancelled) return;
            const sortedThumbnails = fetchedThumbnails.filter(Boolean).sort((a, b) => a.page - b.page);
            setThumbnails(sortedThumbnails);
            if (sortedThumbnails.length > 0) {
                dispatch(showThumbnails());
            }
        });

        return () => {
            isCancelled = true;
            dispatch(hideThumbnails());
        };
    }, [pageCount, documentId]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setThumbnails([]);
    }, [documentId]);

    const handleThumbnailClick = (page) => {
        dispatch(getDocumentPreview({ docId: documentId, page }))
        dispatch(getOCRExtraction({ docId: documentId, page }))
        dispatch(hideBoundingBoxes())
    }

    return (
        <div className='thumbnails-pane' >
            {!showNoCreditError &&
                <div className='thumbnails-container' style={thumbnailsVisible ? { opacity: '1' } : { opacity: '0' }} >
                    {thumbnails?.map(thumbnail =>
                        <div className='thumbnail-container' key={thumbnail.imageUrl} onClick={() => handleThumbnailClick(thumbnail.page)} style={currentPreviewPage === thumbnail.page ? { border: '2px solid #1877F2', backgroundColor: "#EFF8FD" } : { border: '1px solid transparent' }}>
                            <img src={thumbnail.imageUrl} className='thumbnail' alt='preview' />
                            <div className='thumbnail-page' style={{ textAlign: 'center', margin: '5px 0' }}>{thumbnail.page + 1}</div>
                        </div>
                    )}
                </div>}
        </div>
    )
}
