import React from 'react';
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit3 } from "react-icons/fi";



import '../styles/TemplateSubfields.scss';


export default function TemplateSubfields({ item, handleDeleteSubField, handleRenameSubField }) {

    return (
        <div className='subfields-container'>
            {item.fields && item.fields.map((subItem, subIdx) => {
                return (
                    <div className="edit-menu-card__sub" key={subIdx} draggable={true} >
                        <h3 className="label" onClick={() => handleRenameSubField(subItem)}>{subItem.name}</h3>
                        <FiEdit3 className="edit-icon" onClick={() => handleRenameSubField(subItem)} />
                        <RiDeleteBin6Line onClick={() => handleDeleteSubField(subIdx)} />
                    </div>
                )
            })}
        </div>
    )
}
