import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAllTemplates, postNewPipeline } from "./templatesService";
import { addNewInbox } from "../inboxes/inboxSlice";

const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    message: '',
    templatesList: [],
    pipelineId: null,
    isErrorInPipeline: false
}

export const getAllTemplates = createAsyncThunk('templates/getTemplates', async (lang, thunkAPI) => {
    try {
        const response = await fetchAllTemplates(lang);
        return response;
    } catch (error) {
        if (error.response.data.detail) {
            const message = error.response.data.detail
            return thunkAPI.rejectWithValue(message);
        } else {
            const message = error.message
            return thunkAPI.rejectWithValue(message);
        }
    }
})

export const createPipelineAndInbox = createAsyncThunk('templates/createTemplate', async ({ pipelineName, templateId, ocrId, chatId, lang, inboxName, dispatch }, thunkAPI) => {
    try {
        const response = await postNewPipeline(pipelineName, templateId, ocrId, chatId, lang);
        if (!response.id) {
            throw new Error('No response id');
        }
        const newInbox = { "name": inboxName, "pipeline_id": response.id, "ocr_id": ocrId || undefined }
        dispatch(addNewInbox(newInbox))
        return response.id;
    } catch (error) {
        if (error.response.data.detail) {
            const message = error.response.data.detail
            return thunkAPI.rejectWithValue(message);
        } else {
            const message = error.message
            return thunkAPI.rejectWithValue(message);
        }
    }
})

const templatesSlice = createSlice({
    name: 'templates',
    initialState,
    reducers: {
        resetTemplates: (state) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.isError = false;
            state.message = '';
            state.isErrorInPipeline = false
        },
        resetPipelineId: (state) => {
            state.pipelineId = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllTemplates.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllTemplates.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.templatesList = action.payload
            })
            .addCase(getAllTemplates.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload
            })
            .addCase(createPipelineAndInbox.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createPipelineAndInbox.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.pipelineId = action.payload
            })
            .addCase(createPipelineAndInbox.rejected, (state, action) => {
                state.isLoading = false;
                state.isErrorInPipeline = true;
                state.message = action.payload
            })
    }
})

export const { resetTemplates, resetPipelineId } = templatesSlice.actions;
export default templatesSlice.reducer;
