import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { editInboxInfo } from '../features/inboxes/inboxSlice';
import { LuPencilLine } from "react-icons/lu";


export default function InboxName() {
    const { inboxId } = useParams();
    const { inboxDetails } = useSelector(state => state.inbox);
    const [inboxName, setInboxName] = useState('')
    const [newName, setNewName] = useState('')
    const inputRef = useRef(null)
    const [isRenameInputOn, setIsRenameInputOn] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation('generalSettings')

    useEffect(() => {
        if (inboxDetails) {
            setInboxName(inboxDetails?.name)
            setNewName(inboxDetails?.name);

        }
    }, [inboxDetails])

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            dispatch(editInboxInfo({ inboxId: inboxId, newInbox: { name: newName } }));
            setIsRenameInputOn(false);
        }
    }

    useEffect(() => {
        if (isRenameInputOn) {
            inputRef.current.focus();
        }
    }, [isRenameInputOn]);

    const handleBlur = () => {
        setNewName(inboxName);
        setIsRenameInputOn(false);
    }


    return (
        <div className='inbox-name-container inbox-item-container'>
            <p className='inbox-item-label'>{t('inboxName', { ns: 'translation' })}</p>
            {!isRenameInputOn ?
                <div className='inbox-settings-data' onClick={() => setIsRenameInputOn(true)}>
                    <p className='inbox-settings-data__text'>{inboxName}</p>
                    <div className='settings-rename'>
                        <LuPencilLine className='edit-icon' onClick={() => setIsRenameInputOn(true)} title={t('renameInbox', { ns: 'generalSettings' })} />
                    </div>
                </div>
                :
                <input ref={inputRef} type="text" className="inbox-settings-data inbox-settings-data__text inbox-name-input" value={newName} onChange={(e) => setNewName(e.target.value)} onKeyUp={handleKeyPress} onBlur={handleBlur} />
            }
        </div>
    )
}
